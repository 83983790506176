import { Box, Stack, Typography, Link } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import PageLayout from "../components/page-layout";

const NotFound = () => {
  return (
    <PageLayout>
      <Box
        sx={{ height: "100%" }}
        component="div"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        rowGap="24px"
      >
        <Typography variant="h3" fontWeight="bold">
          Oops!
        </Typography>

        <Stack
          textAlign="center"
          alignItems="center"
          direction="column"
          rowGap="8px"
        >
          <Typography variant="body1">
            We could not find the page you were looking for.
          </Typography>

          <Link
            underline="hover"
            href="/"
            rel="noopener noreferrer"
            display="flex"
            alignItems="center"
            variant="overline"
            sx={{ columnGap: "4px" }}
          >
            <ArrowBackIosNewIcon sx={{ fontSize: "12px" }} />

            <Typography
              fontWeight="bold"
              variant="inherit"
              textTransform="uppercase"
            >
              Back to main page
            </Typography>
          </Link>
        </Stack>
      </Box>
    </PageLayout>
  );
};

export default NotFound;
