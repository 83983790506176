import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { THEME_MAIN_COLOR, THEME_SECONDARY_COLOR } from "../../theme";

export const ColoredBorderLoader: any = styled(Box)`
  --border-size: 3px;
  --border-angle: 0turn;
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: conic-gradient(from var(--border-angle), ${THEME_MAIN_COLOR}, ${THEME_MAIN_COLOR} 50%, ${THEME_MAIN_COLOR}), conic-gradient(from var(--border-angle), transparent 20%, ${THEME_MAIN_COLOR}, ${THEME_SECONDARY_COLOR});
  background-size: calc(100% - (var(--border-size) * 2)) calc(100% - (var(--border-size) * 2)), cover;
  border-radius: 6px;
  background-position: center center;
  background-repeat: no-repeat;

  animation: bg-spin 3s linear infinite;
  @keyframes bg-spin {
   to {
     --border-angle: 1turn;
      }
    }
  }

  @property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
  }
`;
