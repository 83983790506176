import { Link } from "@mui/material";
import { ReactNode, useMemo } from "react";

export const MINT_VOUCHER_ABI = [
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "netRecipient",
            type: "address",
          },
          {
            internalType: "address",
            name: "initialRecipient",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "initialRecipientAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "quantity",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "nonce",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "expiry",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "price",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "currency",
            type: "address",
          },
        ],
        internalType: "struct MintVoucherVerification.MintVoucher",
        name: "voucher",
        type: "tuple",
      },
      {
        internalType: "bytes",
        name: "signature",
        type: "bytes",
      },
    ],
    name: "mintWithVoucher",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "voucherNonce",
        type: "uint256",
      },
    ],
    name: "cancelVoucher",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "minter",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "nonce",
        type: "uint256",
      },
    ],
    name: "VoucherCancelled",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes",
        name: "signature",
        type: "bytes",
      },
    ],
    name: "VoucherRedeemed",
    type: "event",
  },
] as any;

export const useItemBuyFlowSteps = (): Array<{
  label: string;
  description: ReactNode;
}> => {
  return useMemo(
    () => [
      {
        label: "Connect MetaMask",
        description:
          "We’ll send your pass to the account you select in MetaMask. If you don’t have MetaMask already, you can install it.",
      },
      {
        label: "Buy Random WABI SABI Pass",
        description: (
          <>
            Buy a random pass by confirming the transaction with MetaMask. There
            will be a nominal gas fee (additional to the buying price) to buy
            your NFT. You will be able to see the estimate for this fee before
            confirming. Learn more{" "}
            <Link
              href="https://metamask.zendesk.com/hc/en-us/articles/4404600179227-User-Guide-Gas"
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
            >
              here
            </Link>
            .
          </>
        ),
      },
      {
        label: "WABI SABI Pass Bought",
        description:
          "After confirming the connected wallet and paying the purchase price (& gas fee) the WABI SABI Pass will be transfered to your wallet.",
      },
    ],
    []
  );
};

export const TX_HASH_LOCAL_STORAGE_KEY = "braw-haus-wabi-sabi-pass-buy-tx-hash";
