import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { GetPublicOrgItemsPaginatedOutputResultsInner } from "../sdk-platform-public";
import { utils } from "ethers";

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}

/**
 * Helper function to generate a UUID.
 */
export const uuidv4 = (): string => {
  let d = new Date().getTime();
  let d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0;
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export function getTimeRemaining(endtime: any) {
  const total = endtime.diff(dayjs());
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  return {
    total,
    days,
    hours,
    minutes,
    seconds,
  };
}

export const isListingAvailable = (
  startTime?: string,
  endTime?: string,
  quantityRemaining?: string
): boolean => {
  return (
    Boolean(startTime) &&
    dayjs(startTime).isAfter(dayjs()) &&
    (!endTime || dayjs(endTime).isAfter(dayjs())) &&
    Boolean(quantityRemaining) &&
    Number(quantityRemaining) > 0
  );
};

export const extractTraits = (
  item: GetPublicOrgItemsPaginatedOutputResultsInner
): Array<{ name: string; value: any }> => {
  let result: Array<{ name: string; value: any } | null> = [];
  if (item?.attributes) {
    const traitNames = Object.keys(item.attributes)?.filter(
      (key) => ["image_url", "meta_animation_url"].indexOf(key) === -1
    ); // include everything (common + custom) except image and animation.
    result = traitNames
      .map((name) => {
        if (!item?.attributes || !(item?.attributes as any)[name]) return null;
        const value = (item.attributes as any)[name];
        return {
          name,
          value,
        };
      })
      .filter(Boolean) as Array<{ name: string; value: any }>;
  }

  return result as Array<{ name: string; value: any }>;
};

export const getTokenPriceLabel = (price?: string, currency?: string) => {
  return !price || !currency
    ? null
    : `${utils.formatEther(price as string)} ${currency}`;
};
