import * as React from "react";
import { TransactionReceipt } from "viem";
import {
  GetPublicOrgItemsPaginatedOutputResultsInner,
  PublicCollectionOutput,
  PublicListingOutput,
  PublicNetworksPaginatedOutputResultsInner,
} from "../sdk-platform-public";
import {
  useAllPublicNetworks,
  useCollection,
  useListing,
  useCollectionItems,
} from "./hooks";
import { COLLECTION_ID, ID_ATTRIBUTE, NETWORK_ID } from "../config";
import { useEffect, useState } from "react";
import { TX_HASH_LOCAL_STORAGE_KEY } from "./constants";
import { useWallet } from "../services/wallet";
import { useWaitForTransaction } from "wagmi";

export interface ListingContextValues {
  listing?: PublicListingOutput;
  listingLoading: boolean;
  collection?: PublicCollectionOutput;
  collectionItems?: GetPublicOrgItemsPaginatedOutputResultsInner[];
  network?: PublicNetworksPaginatedOutputResultsInner;
  listingSoldOut: boolean;
  txHash?: string;
  txHashWalletAddress?: string;
  txReceipt?: TransactionReceipt;
  updateBuyMetadata: (txHash?: string, receipt?: TransactionReceipt) => void;
  txItem?: GetPublicOrgItemsPaginatedOutputResultsInner;
  txItemId?: string;
}

const ListingContext = React.createContext<ListingContextValues>({
  listingLoading: false,
  listingSoldOut: false,
  updateBuyMetadata: () => {},
});

function ListingProvider({ children }: React.PropsWithChildren) {
  const [txHash, setTxHash] = useState<string>();
  const [txHashWalletAddress, setTxHashWalletAddress] = useState<string>();
  const { address } = useWallet();
  const [txReceipt, setTxReceipt] = useState<TransactionReceipt>();
  const [txItem, setTxItem] = useState<
    GetPublicOrgItemsPaginatedOutputResultsInner | undefined
  >(undefined);
  const [txItemId, setTxItemId] = useState<string | undefined>(undefined);
  const {
    data: listing,
    isLoading: listingLoading,
    isError: listingError,
  } = useListing();
  // As long as the tx Item hasn't been found yet & there was a successful tx, then we keep polling the /items endpoint.
  const { data: collectionItems, isLoading: listingItemsLoading } =
    useCollectionItems(
      Number(NETWORK_ID),
      COLLECTION_ID as string,
      txHash && !txItem ? { refetchInterval: 1000 } : undefined
    );
  const { data: publicNetworks, isLoading: networksLoading } =
    useAllPublicNetworks();
  const { data: collection, isLoading: collectionLoading } = useCollection(
    listing?.collection_id
  );
  const { data: txReceiptFromNode } = useWaitForTransaction({
    hash: txHash as any,
    enabled: Boolean(txHash),
  });

  // Ideally, we would not have a NETWORK_ID config value and initialize our rainbow/wagmi providers off of the listing.network_id.
  if (listing?.network_id && Number(NETWORK_ID) !== listing?.network_id) {
    throw Error("Network ID mismatch with listing");
  }

  if (listing?.collection_id && COLLECTION_ID !== listing?.collection_id) {
    throw Error("Collection ID mismatch with listing");
  }

  // Keep our provider up to date with actual tx receipt.
  useEffect(() => {
    setTxReceipt(txReceiptFromNode);
  }, [txReceiptFromNode]);

  // Prevent huge caching of wagmi-cache
  // Only occurs for power users after a lot of tx's
  useEffect(() => {
    window.localStorage.removeItem("wagmi.cache");
  }, []);

  useEffect(() => {
    if (txReceipt && collectionItems.length) {
      const tokenId =
        txReceipt.logs.length &&
        txReceipt.logs[0]?.topics &&
        txReceipt.logs[0].topics.length >= 3
          ? parseInt(txReceipt?.logs[0]?.topics[3] as string).toString()
          : undefined;

      const itemCandidate = tokenId
        ? collectionItems?.find((item) => item.token_id === tokenId)
        : undefined;

      setTxItem(itemCandidate);

      if (ID_ATTRIBUTE) {
        setTxItemId(
          itemCandidate?.attributes &&
            ID_ATTRIBUTE &&
            ID_ATTRIBUTE in itemCandidate.attributes
            ? `#${(itemCandidate.attributes as any)[ID_ATTRIBUTE]}`
            : undefined
        );
      }
    } else {
      setTxItem(undefined);
      setTxItemId(undefined);
    }
  }, [txReceipt, collectionItems]);

  // This hook will ensure the the limit per 'user' ask for this drop was respected as much as possible.
  // The client wants to build a community and wants to avoid hoarding of passes by a small number of users.
  // We therefor store the tx hash in local storage, but as soon as one exists, we don't allow another one.
  //  -> This means that unless the user clears his cache, he won't be able to buy more than one pass. (unless manually manipulating local storage)
  // An alternate flow here is that the tx failed to be submitted. In that case, we don't want to block the user.
  useEffect(() => {
    if (address) {
      const existingTxHashLsKey = Object.keys(localStorage).find(
        (key) => key === TX_HASH_LOCAL_STORAGE_KEY
      );
      const existingTxHashLs = existingTxHashLsKey
        ? window.localStorage.getItem(existingTxHashLsKey)
        : undefined;

      // Store the tx hash in local storage if it's not there yet.
      if (txHash && !existingTxHashLs) {
        window.localStorage.setItem(TX_HASH_LOCAL_STORAGE_KEY, txHash);
        // Clear the tx hash from local storage if it's there and the tx was reverted.
      } else if (txReceipt?.status === "reverted") {
        window.localStorage.removeItem(TX_HASH_LOCAL_STORAGE_KEY);
        // Set the tx hash & wallet from local storage if it's there.
      } else if (!txHash && existingTxHashLs) {
        setTxHash(existingTxHashLs);
      }

      setTxHashWalletAddress(txReceipt?.from);
    }
  }, [txHash, address, txReceipt, txItem]);

  const value = React.useMemo(() => {
    return {
      listing,
      listingLoading:
        listingLoading ||
        listingItemsLoading ||
        networksLoading ||
        collectionLoading,
      collectionItems: collectionItems,
      network: listing?.network_id
        ? publicNetworks.find((network) => network.id === listing?.network_id)
        : undefined,
      collection: collection,
      listingSoldOut: listingError || listing?.quantity_remaining === 0,
      txHash,
      txReceipt,
      txItem,
      txItemId,
      updateBuyMetadata: (txHash?: string, receipt?: TransactionReceipt) => {
        setTxHash(txHash);
        setTxHashWalletAddress(address);
        setTxReceipt(receipt);
      },
      txHashWalletAddress,
    };
  }, [
    listing,
    listingLoading,
    listingItemsLoading,
    networksLoading,
    collectionLoading,
    collectionItems,
    publicNetworks,
    collection,
    listingError,
    txHash,
    txReceipt,
    txItem,
    txItemId,
    address,
    txHashWalletAddress,
  ]);

  return (
    <ListingContext.Provider value={value}>{children}</ListingContext.Provider>
  );
}

function useListingContext() {
  const context = React.useContext(ListingContext);
  if (context === undefined) {
    throw new Error("useListingContext must be used within a ListingProvider");
  }

  return context;
}

export { ListingProvider, useListingContext };
