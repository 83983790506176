import AppProviders from "./AppProviders";
// import GalleryPage from "./pages/GalleryPage";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { BuyPage } from "./pages/BuyPage";
import NotFound from "./pages/NotFound";
import GalleryPage from "./pages/GalleryPage";

export default function App() {
  return (
    <AppProviders>
      <Router>
        <Routes>
          <Route path="/" element={<BuyPage />}></Route>
          <Route path="/buy" element={<BuyPage />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </AppProviders>
  );
}
