import { Box, Divider, Link, Stack, SvgIcon, Typography } from "@mui/material";
import { ReactComponent as twitter } from "../../assets/twitter.svg";
import { ReactComponent as instagram } from "../../assets/instagram.svg";

const Footer = () => {
  return (
    <Box sx={{ display: "grid", rowGap: "16px" }}>
      <Divider />

      <Stack
        display="flex"
        sx={{
          padding: { xs: "8px", lg: "0" },
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: { xs: "center", lg: "space-between" },
          alignItems: "center",
          rowGap: "24px",
          textAlign: "center",
        }}
      >
        <Typography variant="body1" sx={{ maxWidth: "275px" }}>
          <strong>Braw Haus</strong> is a creative studio and Web3 agency
          between New York & Paris.
        </Typography>

        <Stack direction="row" columnGap="16px">
          <Typography
            variant="body2"
            component={Link}
            href="https://consensys.net/nft"
            target="_blank"
            rel="noopener noreferrer"
            underline="hover"
          >
            Powered by ConsenSys NFT
          </Typography>

          <Typography
            variant="body2"
            component={Link}
            href="https://consensys.net/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
            underline="hover"
          >
            Privacy Policy
          </Typography>

          <Typography
            variant="body2"
            component={Link}
            href="https://consensys.net/terms-of-use"
            target="_blank"
            rel="noopener noreferrer"
            underline="hover"
          >
            Terms of Use
          </Typography>
        </Stack>

        <Stack
          flexDirection="row"
          sx={{
            ".MuiSvgIcon-root": { height: "20px", width: "20px" },
            columnGap: "16px",
          }}
        >
          <Link
            href="https://instagram.com/brawhaus"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SvgIcon component={instagram} />
          </Link>

          <Link
            href="https://twitter.com/BrawHaus"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SvgIcon component={twitter} />
          </Link>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Footer;
