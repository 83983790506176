import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import BackgroundImage from "./assets/background.png";

export const THEME_MAIN_COLOR = "#000000";
export const THEME_SECONDARY_COLOR = "#241928";
export const THEME_BODY_1_FONT_SIZE = "14px";

// A custom theme for this app
let theme = createTheme({
  palette: {
    primary: {
      main: THEME_MAIN_COLOR,
    },
    secondary: {
      main: THEME_SECONDARY_COLOR,
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    fontFamily: "Inter",
    h4: {
      fontFamily: "Anton !important",
      letterSpacing: "1px",
      textTransform: "uppercase",
    },
    body1: {
      fontSize: THEME_BODY_1_FONT_SIZE,
      lineHeight: "20px",
    },
    body2: {
      fontSize: "12px",
      lineHeight: "20px",
    },
    caption: {
      fontSize: "12px",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundImage: `url(${BackgroundImage})`,
          backgroundRepeat: "repeat",
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        root: {
          color: THEME_MAIN_COLOR,
          fontFamily: "Inter",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          padding: "4px",
          "> div": {
            backgroundColor: THEME_MAIN_COLOR,
            "p, .MuiTypography-root": {
              color: "white !important",
            },
            color: "white !important",
          },
          backgroundColor: THEME_MAIN_COLOR,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-filledInfo": {
            backgroundColor: THEME_MAIN_COLOR,
            color: "white",
          },
          "&.MuiChip-filledSuccess": {
            backgroundColor: THEME_SECONDARY_COLOR,
            color: "white",
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          columnGap: "16px",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          border: 0,
          borderRadius: 0,
          color: THEME_MAIN_COLOR,
          backgroundColor: "transparent",
          padding: 0,
          textTransform: "none",

          ".MuiChip-root": {
            backgroundColor: "transparent",
            borderRadius: 0,
            border: `1px solid ${THEME_MAIN_COLOR}`,
            color: THEME_MAIN_COLOR,
            height: "18px",

            ".MuiChip-label": {
              padding: "0px 6px",
            },
          },

          "&.Mui-selected": {
            borderBottom: `1px solid ${THEME_MAIN_COLOR}`,
            backgroundColor: "transparent",
            fontWeight: "bold",

            ".MuiChip-root": {
              border: 0,
              backgroundColor: THEME_MAIN_COLOR,
              color: "white",
            },
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: 0,
          boxShadow: "none",
          padding: 0,
          "&:before": {
            display: "none",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
          minHeight: "0 !important",
          "&.Mui-expanded": {
            borderBottom: `1px solid ${THEME_MAIN_COLOR}`,
          },
        },
        content: {
          margin: 0,
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          border: `1px solid ${THEME_MAIN_COLOR}`,
          backgroundColor: "transparent",
          padding: "16px",
          boxShadow: "none !important",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          color: "white",
          ".MuiIconButton-root": {
            color: "white",
          },
        },

        popper: {
          "> div": {
            padding: "10px !important",
            backgroundColor: "#241928",
            color: THEME_MAIN_COLOR,
            ".MuiAutocomplete-option": {
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: "#241929",
              },
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Anton !important",
          letterSpacing: "1px",
          padding: "4px 16px",
          borderRadius: "0",
          boxShadow: "none !important",
          "&.Mui-disabled": {
            opacity: 0.8,
          },
        },

        containedPrimary: {
          backgroundColor: THEME_MAIN_COLOR,
          color: "#fff",
        },

        outlinedPrimary: {
          border: `2px solid ${THEME_MAIN_COLOR}`,
          backgroundColor: "transparent",
        },

        textPrimary: {
          fontFamily: "Inter !important",
          letterSpacing: "0px",
          padding: "4px",
          height: "auto !important",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "transparent",
            textDecoration: "underline",
          },
        },
      },
      defaultProps: {
        variant: "contained",
        color: "primary",
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          path: {
            fill: `${THEME_MAIN_COLOR} !important`,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "rgba(232, 63, 39, 1)",
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
