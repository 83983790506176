import "@rainbow-me/rainbowkit/styles.css";

import {
  RainbowKitProvider,
  connectorsForWallets,
} from "@rainbow-me/rainbowkit";

import {
  metaMaskWallet,
  walletConnectWallet,
  injectedWallet,
} from "@rainbow-me/rainbowkit/wallets";

import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { lineaTestnet, mainnet, goerli } from "viem/chains";
import { infuraProvider } from "wagmi/providers/infura";
import { INFURA_KEY, WALLET_CONNECT_PROJECT_ID } from "./config";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ListingProvider } from "./utils/ListingContextProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { NETWORK_ID } from "./config";

// For this drop, we don't use the listing.networkId to determine the chain(s) we support.
let supportedChain = null;

if (NETWORK_ID && Number(NETWORK_ID) === 1) {
  supportedChain = mainnet;
} else if (NETWORK_ID && Number(NETWORK_ID) === 5) {
  supportedChain = goerli;
} else if (NETWORK_ID && Number(NETWORK_ID) === 59140) {
  supportedChain = lineaTestnet;
}

const { chains, publicClient } = configureChains(
  supportedChain ? [supportedChain] : [mainnet],
  [infuraProvider({ apiKey: INFURA_KEY ?? "" })]
);

const needsInjectedWalletFallback =
  typeof window !== "undefined" &&
  window.ethereum &&
  !window.ethereum.isMetaMask;

const connectors: any = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      metaMaskWallet({
        chains,
        projectId: WALLET_CONNECT_PROJECT_ID as string,
      } as any),
    ], // no project id
  },
  {
    groupName: "Others",
    wallets: [
      walletConnectWallet({
        chains,
        projectId: WALLET_CONNECT_PROJECT_ID as string,
      }),
      ...(needsInjectedWalletFallback ? [injectedWallet({ chains })] : []),
    ],
  },
]);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      cacheTime: 0,
      staleTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
});

const config = createConfig({
  autoConnect: true,
  storage: undefined,
  connectors: connectors,
  publicClient,
});

const AppProviders = ({ children }: any) => {
  return (
    <QueryClientProvider client={queryClient}>
      <WagmiConfig config={config}>
        <RainbowKitProvider
          chains={chains}
          appInfo={{
            appName: "WABI SABI PASS, by Braw Haus",
          }}
        >
          <ListingProvider>{children}</ListingProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </RainbowKitProvider>
      </WagmiConfig>
    </QueryClientProvider>
  );
};

export default AppProviders;
