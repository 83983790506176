import { useMemo } from "react";
import Box from "@mui/material/Box";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { Stack, Typography } from "@mui/material";
import ClipboardContent from "./ClipboardContent";

interface EthereumAddressProps {
  value: string;
  // Formatting
  shortFormat?: boolean;
  shortFormatLengthOnLeftSide?: number;
  shortFormatLengthOnRightSide?: number;
  // Explorer icon
  showCopyIcon?: boolean;
}

export const EthereumAddress = ({
  value = "",
  shortFormat = true,
  shortFormatLengthOnLeftSide = 4,
  shortFormatLengthOnRightSide = 4,
  showCopyIcon = false,
}: EthereumAddressProps) => {
  const displayedAddress = useMemo(() => {
    if (!shortFormat || !value) return value;
    return `${value.slice(0, 2 + shortFormatLengthOnLeftSide)}...${value.slice(
      0 - shortFormatLengthOnRightSide
    )}`;
  }, [
    value,
    shortFormat,
    shortFormatLengthOnLeftSide,
    shortFormatLengthOnRightSide,
  ]);

  if (!displayedAddress) return <></>;

  return (
    <Box sx={{ display: "flex" }}>
      <ClipboardContent
        tooltipContext="this Ethereum address"
        clipboardValue={value}
        sx={{ "&:hover": { cursor: "pointer" } }}
      >
        <Stack direction="row" spacing={1}>
          <Typography
            variant="body1"
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              flexShrink: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            {displayedAddress}
          </Typography>
          {showCopyIcon && <ContentCopyOutlinedIcon />}
        </Stack>
      </ClipboardContent>
    </Box>
  );
};

export default EthereumAddress;
