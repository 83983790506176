/* tslint:disable */
/* eslint-disable */
/**
 * ConsenSys NFT Public API
 * by <a href=\"https://consensys.net/nft/\">ConsenSys NFT</a>.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration'
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import globalAxios from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common'
import type { RequestArgs } from './base'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface AppApiPublicV1EndpointsCancelPurchaseIntentPath
 */
export interface AppApiPublicV1EndpointsCancelPurchaseIntentPath {
  /**
   *
   * @type {string}
   * @memberof AppApiPublicV1EndpointsCancelPurchaseIntentPath
   */
  purchase_intent_id: string
}
/**
 *
 * @export
 * @interface AppApiPublicV1EndpointsGetCollectionStatsPublicPath
 */
export interface AppApiPublicV1EndpointsGetCollectionStatsPublicPath {
  /**
   *
   * @type {string}
   * @memberof AppApiPublicV1EndpointsGetCollectionStatsPublicPath
   */
  collection_id: string
}
/**
 *
 * @export
 * @interface AppApiPublicV1EndpointsGetPublicCapabilityPath
 */
export interface AppApiPublicV1EndpointsGetPublicCapabilityPath {
  /**
   *
   * @type {string}
   * @memberof AppApiPublicV1EndpointsGetPublicCapabilityPath
   */
  capability: string
}
/**
 *
 * @export
 * @interface AppApiPublicV1EndpointsGetPublicItemHistoryPath
 */
export interface AppApiPublicV1EndpointsGetPublicItemHistoryPath {
  /**
   *
   * @type {string}
   * @memberof AppApiPublicV1EndpointsGetPublicItemHistoryPath
   */
  item_id: string
}
/**
 *
 * @export
 * @interface AppApiPublicV1EndpointsGetPublicItemOwnersPath
 */
export interface AppApiPublicV1EndpointsGetPublicItemOwnersPath {
  /**
   *
   * @type {string}
   * @memberof AppApiPublicV1EndpointsGetPublicItemOwnersPath
   */
  item_id: string
}
/**
 *
 * @export
 * @interface AppApiPublicV1EndpointsGetPublicTokenMetadataPath
 */
export interface AppApiPublicV1EndpointsGetPublicTokenMetadataPath {
  /**
   *
   * @type {string}
   * @memberof AppApiPublicV1EndpointsGetPublicTokenMetadataPath
   */
  collection_id: string
  /**
   * Uint256
   * @type {string}
   * @memberof AppApiPublicV1EndpointsGetPublicTokenMetadataPath
   */
  token_id: string
}
/**
 *
 * @export
 * @interface AppApiPublicV1EndpointsPublicGetCollectionByIdPath
 */
export interface AppApiPublicV1EndpointsPublicGetCollectionByIdPath {
  /**
   *
   * @type {string}
   * @memberof AppApiPublicV1EndpointsPublicGetCollectionByIdPath
   */
  collection_id: string
}
/**
 *
 * @export
 * @interface AppApiPublicV1EndpointsPublicGetEmailClaimPath
 */
export interface AppApiPublicV1EndpointsPublicGetEmailClaimPath {
  /**
   *
   * @type {string}
   * @memberof AppApiPublicV1EndpointsPublicGetEmailClaimPath
   */
  claim_token: string
}
/**
 *
 * @export
 * @interface AppApiPublicV1EndpointsPublicGetItemPath
 */
export interface AppApiPublicV1EndpointsPublicGetItemPath {
  /**
   *
   * @type {string}
   * @memberof AppApiPublicV1EndpointsPublicGetItemPath
   */
  item_id: string
}
/**
 *
 * @export
 * @interface AppApiPublicV1EndpointsPublicGetListingPath
 */
export interface AppApiPublicV1EndpointsPublicGetListingPath {
  /**
   *
   * @type {string}
   * @memberof AppApiPublicV1EndpointsPublicGetListingPath
   */
  listing_id: string
}
/**
 *
 * @export
 * @interface AppApiPublicV1EndpointsPublicGetPendingMintVouchersPath
 */
export interface AppApiPublicV1EndpointsPublicGetPendingMintVouchersPath {
  /**
   * ChecksumAddress
   * @type {string}
   * @memberof AppApiPublicV1EndpointsPublicGetPendingMintVouchersPath
   */
  eth_address: string
}
/**
 *
 * @export
 * @interface AppApiPublicV1EndpointsPublicRedeemEmailClaimPath
 */
export interface AppApiPublicV1EndpointsPublicRedeemEmailClaimPath {
  /**
   *
   * @type {string}
   * @memberof AppApiPublicV1EndpointsPublicRedeemEmailClaimPath
   */
  claim_token: string
}
/**
 *
 * @export
 * @interface AppApiPublicV1EndpointsVerifyEmailClaimPath
 */
export interface AppApiPublicV1EndpointsVerifyEmailClaimPath {
  /**
   *
   * @type {string}
   * @memberof AppApiPublicV1EndpointsVerifyEmailClaimPath
   */
  claim_token: string
}
/**
 *
 * @export
 * @interface CollectionStats
 */
export interface CollectionStats {
  /**
   *
   * @type {Array<CollectionStatsAttributesInner>}
   * @memberof CollectionStats
   */
  attributes: Array<CollectionStatsAttributesInner>
  /**
   *
   * @type {Array<string>}
   * @memberof CollectionStats
   */
  excluded: Array<string>
  /**
   *
   * @type {number}
   * @memberof CollectionStats
   */
  tokens_indexed: number
}
/**
 *
 * @export
 * @interface CollectionStatsAttributesInner
 */
export interface CollectionStatsAttributesInner {
  /**
   *
   * @type {number}
   * @memberof CollectionStatsAttributesInner
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof CollectionStatsAttributesInner
   */
  name: string
  /**
   *
   * @type {Array<CollectionStatsAttributesInnerValuesInner>}
   * @memberof CollectionStatsAttributesInner
   */
  values: Array<CollectionStatsAttributesInnerValuesInner>
}
/**
 *
 * @export
 * @interface CollectionStatsAttributesInnerValuesInner
 */
export interface CollectionStatsAttributesInnerValuesInner {
  /**
   *
   * @type {number}
   * @memberof CollectionStatsAttributesInnerValuesInner
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof CollectionStatsAttributesInnerValuesInner
   */
  name: string
}
/**
 *
 * @export
 * @interface CreatePurchaseIntentInput
 */
export interface CreatePurchaseIntentInput {
  /**
   *
   * @type {CreatePurchaseIntentInputBuyer}
   * @memberof CreatePurchaseIntentInput
   */
  buyer: CreatePurchaseIntentInputBuyer
  /**
   *
   * @type {string}
   * @memberof CreatePurchaseIntentInput
   */
  listing_id: string
  /**
   *
   * @type {string}
   * @memberof CreatePurchaseIntentInput
   */
  provider: CreatePurchaseIntentInputProviderEnum
  /**
   *
   * @type {number}
   * @memberof CreatePurchaseIntentInput
   */
  quantity: number
}

export const CreatePurchaseIntentInputProviderEnum = {
  Stripe: 'STRIPE',
  Coinbase: 'COINBASE',
  MintVoucher: 'MINT_VOUCHER',
  EmailClaim: 'EMAIL_CLAIM',
} as const

export type CreatePurchaseIntentInputProviderEnum =
  (typeof CreatePurchaseIntentInputProviderEnum)[keyof typeof CreatePurchaseIntentInputProviderEnum]

/**
 *
 * @export
 * @interface CreatePurchaseIntentInputBuyer
 */
export interface CreatePurchaseIntentInputBuyer {
  /**
   * Email
   * @type {string}
   * @memberof CreatePurchaseIntentInputBuyer
   */
  email?: string | null
  /**
   * ChecksumAddress
   * @type {string}
   * @memberof CreatePurchaseIntentInputBuyer
   */
  eth_address?: string | null
}
/**
 *
 * @export
 * @interface CreatePurchaseIntentOutput
 */
export interface CreatePurchaseIntentOutput {
  /**
   *
   * @type {object}
   * @memberof CreatePurchaseIntentOutput
   */
  data: object
  /**
   * UtcDateTime
   * @type {string}
   * @memberof CreatePurchaseIntentOutput
   */
  expires_at: string
  /**
   *
   * @type {string}
   * @memberof CreatePurchaseIntentOutput
   */
  id: string
}
/**
 *
 * @export
 * @interface GetClaimOutput
 */
export interface GetClaimOutput {
  /**
   *
   * @type {boolean}
   * @memberof GetClaimOutput
   */
  is_active: boolean
  /**
   *
   * @type {boolean}
   * @memberof GetClaimOutput
   */
  is_claimed: boolean
  /**
   *
   * @type {GetClaimOutputItem}
   * @memberof GetClaimOutput
   */
  item: GetClaimOutputItem
  /**
   *
   * @type {number}
   * @memberof GetClaimOutput
   */
  quantity: number
  /**
   *
   * @type {GetClaimOutputVoucher}
   * @memberof GetClaimOutput
   */
  voucher?: GetClaimOutputVoucher | null
}
/**
 *
 * @export
 * @interface GetClaimOutputItem
 */
export interface GetClaimOutputItem {
  /**
   *
   * @type {object}
   * @memberof GetClaimOutputItem
   */
  attributes: object
  /**
   *
   * @type {string}
   * @memberof GetClaimOutputItem
   */
  collection_id: string
  /**
   *
   * @type {string}
   * @memberof GetClaimOutputItem
   */
  id: string
  /**
   *
   * @type {GetClaimOutputItemListing}
   * @memberof GetClaimOutputItem
   */
  listing?: GetClaimOutputItemListing | null
  /**
   *
   * @type {GetClaimOutputItemMedia}
   * @memberof GetClaimOutputItem
   */
  media: GetClaimOutputItemMedia
  /**
   *
   * @type {GetClaimOutputItemTokenContract}
   * @memberof GetClaimOutputItem
   */
  token_contract: GetClaimOutputItemTokenContract
  /**
   * Uint256
   * @type {string}
   * @memberof GetClaimOutputItem
   */
  token_id: string
}
/**
 *
 * @export
 * @interface GetClaimOutputItemListing
 */
export interface GetClaimOutputItemListing {
  /**
   *
   * @type {object}
   * @memberof GetClaimOutputItemListing
   */
  data: object
  /**
   *
   * @type {string}
   * @memberof GetClaimOutputItemListing
   */
  type: GetClaimOutputItemListingTypeEnum
}

export const GetClaimOutputItemListingTypeEnum = {
  SetPrice: 'SET_PRICE',
  OnchainEnglishAuction: 'ONCHAIN_ENGLISH_AUCTION',
  FiatSetPrice: 'FIAT_SET_PRICE',
  BuyerMint: 'BUYER_MINT',
  FiatLazyMint: 'FIAT_LAZY_MINT',
  Default: 'DEFAULT',
} as const

export type GetClaimOutputItemListingTypeEnum =
  (typeof GetClaimOutputItemListingTypeEnum)[keyof typeof GetClaimOutputItemListingTypeEnum]

/**
 *
 * @export
 * @interface GetClaimOutputItemMedia
 */
export interface GetClaimOutputItemMedia {
  /**
   *
   * @type {GetClaimOutputItemMediaImage}
   * @memberof GetClaimOutputItemMedia
   */
  image?: GetClaimOutputItemMediaImage | null
}
/**
 *
 * @export
 * @interface GetClaimOutputItemMediaImage
 */
export interface GetClaimOutputItemMediaImage {
  /**
   *
   * @type {string}
   * @memberof GetClaimOutputItemMediaImage
   */
  cid?: string | null
  /**
   *
   * @type {string}
   * @memberof GetClaimOutputItemMediaImage
   */
  full?: string | null
  /**
   *
   * @type {string}
   * @memberof GetClaimOutputItemMediaImage
   */
  original?: string | null
  /**
   *
   * @type {string}
   * @memberof GetClaimOutputItemMediaImage
   */
  thumb?: string | null
  /**
   *
   * @type {string}
   * @memberof GetClaimOutputItemMediaImage
   */
  tiny?: string | null
}
/**
 *
 * @export
 * @interface GetClaimOutputItemTokenContract
 */
export interface GetClaimOutputItemTokenContract {
  /**
   * ChecksumAddress
   * @type {string}
   * @memberof GetClaimOutputItemTokenContract
   */
  address?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof GetClaimOutputItemTokenContract
   */
  capabilities: Array<GetClaimOutputItemTokenContractCapabilitiesEnum>
  /**
   *
   * @type {string}
   * @memberof GetClaimOutputItemTokenContract
   */
  deploy_tx_id?: string | null
  /**
   *
   * @type {number}
   * @memberof GetClaimOutputItemTokenContract
   */
  network_id?: number | null
  /**
   *
   * @type {string}
   * @memberof GetClaimOutputItemTokenContract
   */
  symbol?: string | null
  /**
   *
   * @type {string}
   * @memberof GetClaimOutputItemTokenContract
   */
  token_type: GetClaimOutputItemTokenContractTokenTypeEnum
  /**
   *
   * @type {string}
   * @memberof GetClaimOutputItemTokenContract
   */
  tx_hash?: string | null
}

export const GetClaimOutputItemTokenContractCapabilitiesEnum = {
  Erc20: 'ERC20',
  Erc20Metadata: 'ERC20_METADATA',
  Erc721: 'ERC721',
  Erc721Metadata: 'ERC721_METADATA',
  BurnToken: 'BURN_TOKEN',
  MintToAddress: 'MINT_TO_ADDRESS',
  IsMinter: 'IS_MINTER',
  Erc1155: 'ERC1155',
  Erc1155MetadataUri: 'ERC1155_METADATA_URI',
  WithdrawTokensTo: 'WITHDRAW_TOKENS_TO',
  TokenMintPrices: 'TOKEN_MINT_PRICES',
  MintMultipleTo: 'MINT_MULTIPLE_TO',
  AuctionV1: 'AUCTION_V1',
  RestrictedAuctionV1: 'RESTRICTED_AUCTION_V1',
  ExchangeV1: 'EXCHANGE_V1',
  OzAccessControl: 'OZ_ACCESS_CONTROL',
  OzOwnable: 'OZ_OWNABLE',
  ManageSupply: 'MANAGE_SUPPLY',
  SetBaseUri: 'SET_BASE_URI',
  SetBaseUriPermanent: 'SET_BASE_URI_PERMANENT',
  CreateWalletpoolProxy: 'CREATE_WALLETPOOL_PROXY',
  ProxyExecute: 'PROXY_EXECUTE',
  MintVoucher: 'MINT_VOUCHER',
  MintVoucherV2: 'MINT_VOUCHER_V2',
  GrantRevokeMany: 'GRANT_REVOKE_MANY',
  Erc2981: 'ERC2981',
  Eip165: 'EIP165',
  ContractRoyalty: 'CONTRACT_ROYALTY',
  PerTokenRoyalty: 'PER_TOKEN_ROYALTY',
  ContractNameSymbol: 'CONTRACT_NAME_SYMBOL',
  CreateForAdminMint: 'CREATE_FOR_ADMIN_MINT',
  CreateForAdminMintWithUri: 'CREATE_FOR_ADMIN_MINT_WITH_URI',
  AdminMintSingle: 'ADMIN_MINT_SINGLE',
  AdminMintMultiple: 'ADMIN_MINT_MULTIPLE',
  AdminMintSingleUri: 'ADMIN_MINT_SINGLE_URI',
  AdminMintMultipleNoId: 'ADMIN_MINT_MULTIPLE_NO_ID',
  OsRegistryFilter: 'OS_REGISTRY_FILTER',
  OsRegistryFilterToggle: 'OS_REGISTRY_FILTER_TOGGLE',
  DeprecatedMintToAddressWithTokenUri: 'DEPRECATED_MINT_TO_ADDRESS_WITH_TOKEN_URI',
  DeprecatedAdminMint: 'DEPRECATED_ADMIN_MINT',
  Disperse: 'DISPERSE',
} as const

export type GetClaimOutputItemTokenContractCapabilitiesEnum =
  (typeof GetClaimOutputItemTokenContractCapabilitiesEnum)[keyof typeof GetClaimOutputItemTokenContractCapabilitiesEnum]
export const GetClaimOutputItemTokenContractTokenTypeEnum = {
  Erc721: 'ERC721',
  Erc1155: 'ERC1155',
} as const

export type GetClaimOutputItemTokenContractTokenTypeEnum =
  (typeof GetClaimOutputItemTokenContractTokenTypeEnum)[keyof typeof GetClaimOutputItemTokenContractTokenTypeEnum]

/**
 *
 * @export
 * @interface GetClaimOutputVoucher
 */
export interface GetClaimOutputVoucher {
  /**
   * ChecksumAddress
   * @type {string}
   * @memberof GetClaimOutputVoucher
   */
  contract: string
  /**
   * ChecksumAddress
   * @type {string}
   * @memberof GetClaimOutputVoucher
   */
  minter: string
  /**
   *
   * @type {number}
   * @memberof GetClaimOutputVoucher
   */
  network_id: number
  /**
   * HexString
   * @type {string}
   * @memberof GetClaimOutputVoucher
   */
  signature: string
  /**
   *
   * @type {GetClaimOutputVoucherVoucher}
   * @memberof GetClaimOutputVoucher
   */
  voucher: GetClaimOutputVoucherVoucher
}
/**
 *
 * @export
 * @interface GetClaimOutputVoucherVoucher
 */
export interface GetClaimOutputVoucherVoucher {
  /**
   * ChecksumAddress
   * @type {string}
   * @memberof GetClaimOutputVoucherVoucher
   */
  currency: string
  /**
   *
   * @type {number}
   * @memberof GetClaimOutputVoucherVoucher
   */
  expiry: number
  /**
   * ChecksumAddress
   * @type {string}
   * @memberof GetClaimOutputVoucherVoucher
   */
  initial_recipient: string
  /**
   * Uint256
   * @type {string}
   * @memberof GetClaimOutputVoucherVoucher
   */
  initial_recipient_amount: string
  /**
   * ChecksumAddress
   * @type {string}
   * @memberof GetClaimOutputVoucherVoucher
   */
  net_recipient: string
  /**
   *
   * @type {number}
   * @memberof GetClaimOutputVoucherVoucher
   */
  nonce: number
  /**
   * Uint256
   * @type {string}
   * @memberof GetClaimOutputVoucherVoucher
   */
  price: string
  /**
   *
   * @type {number}
   * @memberof GetClaimOutputVoucherVoucher
   */
  quantity: number
  /**
   * Uint256
   * @type {string}
   * @memberof GetClaimOutputVoucherVoucher
   */
  token_id: string
  /**
   *
   * @type {string}
   * @memberof GetClaimOutputVoucherVoucher
   */
  token_uri?: string | null
}
/**
 *
 * @export
 * @interface GetPublicOrgItemsPaginatedInput
 */
export interface GetPublicOrgItemsPaginatedInput {
  /**
   *
   * @type {Array<string>}
   * @memberof GetPublicOrgItemsPaginatedInput
   */
  attributes?: Array<string> | null
  /**
   *
   * @type {Array<string>}
   * @memberof GetPublicOrgItemsPaginatedInput
   */
  collection_ids?: Array<string> | null
  /**
   * SafeString
   * @type {string}
   * @memberof GetPublicOrgItemsPaginatedInput
   */
  cursor?: string | null
  /**
   * MediumPaginationLimit
   * @type {number}
   * @memberof GetPublicOrgItemsPaginatedInput
   */
  limit?: number
  /**
   *
   * @type {Array<number>}
   * @memberof GetPublicOrgItemsPaginatedInput
   */
  network_ids?: Array<number> | null
  /**
   *
   * @type {string}
   * @memberof GetPublicOrgItemsPaginatedInput
   */
  order_direction?: GetPublicOrgItemsPaginatedInputOrderDirectionEnum
  /**
   *
   * @type {string}
   * @memberof GetPublicOrgItemsPaginatedInput
   */
  organization_id: string
  /**
   *
   * @type {Array<number>}
   * @memberof GetPublicOrgItemsPaginatedInput
   */
  token_ids?: Array<number> | null
}

export const GetPublicOrgItemsPaginatedInputOrderDirectionEnum = {
  Asc: 'ASC',
  Desc: 'DESC',
} as const

export type GetPublicOrgItemsPaginatedInputOrderDirectionEnum =
  (typeof GetPublicOrgItemsPaginatedInputOrderDirectionEnum)[keyof typeof GetPublicOrgItemsPaginatedInputOrderDirectionEnum]

/**
 *
 * @export
 * @interface GetPublicOrgItemsPaginatedOutput
 */
export interface GetPublicOrgItemsPaginatedOutput {
  /**
   *
   * @type {string}
   * @memberof GetPublicOrgItemsPaginatedOutput
   */
  cursor?: string | null
  /**
   *
   * @type {boolean}
   * @memberof GetPublicOrgItemsPaginatedOutput
   */
  has_more: boolean
  /**
   *
   * @type {Array<GetPublicOrgItemsPaginatedOutputResultsInner>}
   * @memberof GetPublicOrgItemsPaginatedOutput
   */
  results: Array<GetPublicOrgItemsPaginatedOutputResultsInner>
  /**
   *
   * @type {number}
   * @memberof GetPublicOrgItemsPaginatedOutput
   */
  total_results: number
}
/**
 *
 * @export
 * @interface GetPublicOrgItemsPaginatedOutputResultsInner
 */
export interface GetPublicOrgItemsPaginatedOutputResultsInner {
  /**
   *
   * @type {object}
   * @memberof GetPublicOrgItemsPaginatedOutputResultsInner
   */
  attributes: object
  /**
   *
   * @type {string}
   * @memberof GetPublicOrgItemsPaginatedOutputResultsInner
   */
  collection_id: string
  /**
   * ChecksumAddress
   * @type {string}
   * @memberof GetPublicOrgItemsPaginatedOutputResultsInner
   */
  contract_address?: string | null
  /**
   *
   * @type {string}
   * @memberof GetPublicOrgItemsPaginatedOutputResultsInner
   */
  id: string
  /**
   *
   * @type {GetClaimOutputItemMedia}
   * @memberof GetPublicOrgItemsPaginatedOutputResultsInner
   */
  media: GetClaimOutputItemMedia
  /**
   *
   * @type {number}
   * @memberof GetPublicOrgItemsPaginatedOutputResultsInner
   */
  network_id: number
  /**
   * Uint256
   * @type {string}
   * @memberof GetPublicOrgItemsPaginatedOutputResultsInner
   */
  token_id: string
  /**
   *
   * @type {string}
   * @memberof GetPublicOrgItemsPaginatedOutputResultsInner
   */
  token_type: GetPublicOrgItemsPaginatedOutputResultsInnerTokenTypeEnum
}

export const GetPublicOrgItemsPaginatedOutputResultsInnerTokenTypeEnum = {
  Erc721: 'ERC721',
  Erc1155: 'ERC1155',
} as const

export type GetPublicOrgItemsPaginatedOutputResultsInnerTokenTypeEnum =
  (typeof GetPublicOrgItemsPaginatedOutputResultsInnerTokenTypeEnum)[keyof typeof GetPublicOrgItemsPaginatedOutputResultsInnerTokenTypeEnum]

/**
 *
 * @export
 * @interface GetVouchersPaginatedInput
 */
export interface GetVouchersPaginatedInput {
  /**
   * ChecksumAddress
   * @type {string}
   * @memberof GetVouchersPaginatedInput
   */
  contract_address?: string | null
  /**
   * SafeString
   * @type {string}
   * @memberof GetVouchersPaginatedInput
   */
  cursor?: string | null
  /**
   * MediumPaginationLimit
   * @type {number}
   * @memberof GetVouchersPaginatedInput
   */
  limit?: number
  /**
   *
   * @type {number}
   * @memberof GetVouchersPaginatedInput
   */
  network_id?: number | null
  /**
   *
   * @type {string}
   * @memberof GetVouchersPaginatedInput
   */
  order_direction?: GetVouchersPaginatedInputOrderDirectionEnum
}

export const GetVouchersPaginatedInputOrderDirectionEnum = {
  Asc: 'ASC',
  Desc: 'DESC',
} as const

export type GetVouchersPaginatedInputOrderDirectionEnum =
  (typeof GetVouchersPaginatedInputOrderDirectionEnum)[keyof typeof GetVouchersPaginatedInputOrderDirectionEnum]

/**
 *
 * @export
 * @interface GetVouchersPaginatedOutput
 */
export interface GetVouchersPaginatedOutput {
  /**
   *
   * @type {string}
   * @memberof GetVouchersPaginatedOutput
   */
  cursor?: string | null
  /**
   *
   * @type {boolean}
   * @memberof GetVouchersPaginatedOutput
   */
  has_more: boolean
  /**
   *
   * @type {Array<GetVouchersPaginatedOutputResultsInner>}
   * @memberof GetVouchersPaginatedOutput
   */
  results: Array<GetVouchersPaginatedOutputResultsInner>
  /**
   *
   * @type {number}
   * @memberof GetVouchersPaginatedOutput
   */
  total_results: number
}
/**
 *
 * @export
 * @interface GetVouchersPaginatedOutputResultsInner
 */
export interface GetVouchersPaginatedOutputResultsInner {
  /**
   * ChecksumAddress
   * @type {string}
   * @memberof GetVouchersPaginatedOutputResultsInner
   */
  contract: string
  /**
   * ChecksumAddress
   * @type {string}
   * @memberof GetVouchersPaginatedOutputResultsInner
   */
  minter: string
  /**
   *
   * @type {number}
   * @memberof GetVouchersPaginatedOutputResultsInner
   */
  network_id: number
  /**
   * HexString
   * @type {string}
   * @memberof GetVouchersPaginatedOutputResultsInner
   */
  signature: string
  /**
   *
   * @type {GetClaimOutputVoucherVoucher}
   * @memberof GetVouchersPaginatedOutputResultsInner
   */
  voucher: GetClaimOutputVoucherVoucher
}
/**
 *
 * @export
 * @interface PublicCollectionOutput
 */
export interface PublicCollectionOutput {
  /**
   *
   * @type {string}
   * @memberof PublicCollectionOutput
   */
  base_uri?: string | null
  /**
   * ChecksumAddress
   * @type {string}
   * @memberof PublicCollectionOutput
   */
  contract: string
  /**
   *
   * @type {string}
   * @memberof PublicCollectionOutput
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof PublicCollectionOutput
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof PublicCollectionOutput
   */
  network_id: number
  /**
   *
   * @type {number}
   * @memberof PublicCollectionOutput
   */
  media: { header_image_url: string, thumbnail_image_url: string}
}
/**
 *
 * @export
 * @interface PublicCollectionsPaginatedInput
 */
export interface PublicCollectionsPaginatedInput {
  /**
   * SafeString
   * @type {string}
   * @memberof PublicCollectionsPaginatedInput
   */
  cursor?: string | null
  /**
   * PaginationLimit
   * @type {number}
   * @memberof PublicCollectionsPaginatedInput
   */
  limit?: number
  /**
   *
   * @type {string}
   * @memberof PublicCollectionsPaginatedInput
   */
  order_direction?: PublicCollectionsPaginatedInputOrderDirectionEnum
  /**
   *
   * @type {string}
   * @memberof PublicCollectionsPaginatedInput
   */
  organization_id: string
}

export const PublicCollectionsPaginatedInputOrderDirectionEnum = {
  Asc: 'ASC',
  Desc: 'DESC',
} as const

export type PublicCollectionsPaginatedInputOrderDirectionEnum =
  (typeof PublicCollectionsPaginatedInputOrderDirectionEnum)[keyof typeof PublicCollectionsPaginatedInputOrderDirectionEnum]

/**
 *
 * @export
 * @interface PublicCollectionsPaginatedOutput
 */
export interface PublicCollectionsPaginatedOutput {
  /**
   *
   * @type {string}
   * @memberof PublicCollectionsPaginatedOutput
   */
  cursor?: string | null
  /**
   *
   * @type {boolean}
   * @memberof PublicCollectionsPaginatedOutput
   */
  has_more: boolean
  /**
   *
   * @type {Array<PublicCollectionsPaginatedOutputResultsInner>}
   * @memberof PublicCollectionsPaginatedOutput
   */
  results: Array<PublicCollectionsPaginatedOutputResultsInner>
  /**
   *
   * @type {number}
   * @memberof PublicCollectionsPaginatedOutput
   */
  total_results: number
}
/**
 *
 * @export
 * @interface PublicCollectionsPaginatedOutputResultsInner
 */
export interface PublicCollectionsPaginatedOutputResultsInner {
  /**
   *
   * @type {string}
   * @memberof PublicCollectionsPaginatedOutputResultsInner
   */
  base_uri?: string | null
  /**
   * ChecksumAddress
   * @type {string}
   * @memberof PublicCollectionsPaginatedOutputResultsInner
   */
  contract: string
  /**
   *
   * @type {string}
   * @memberof PublicCollectionsPaginatedOutputResultsInner
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof PublicCollectionsPaginatedOutputResultsInner
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof PublicCollectionsPaginatedOutputResultsInner
   */
  network_id: number
}
/**
 *
 * @export
 * @interface PublicGetCapabilitiesOutput
 */
export interface PublicGetCapabilitiesOutput {
  /**
   *
   * @type {Array<object>}
   * @memberof PublicGetCapabilitiesOutput
   */
  abi: Array<object>
  /**
   *
   * @type {string}
   * @memberof PublicGetCapabilitiesOutput
   */
  name: string
}
/**
 *
 * @export
 * @interface PublicGetCapabilitiesPaginatedOutput
 */
export interface PublicGetCapabilitiesPaginatedOutput {
  /**
   *
   * @type {string}
   * @memberof PublicGetCapabilitiesPaginatedOutput
   */
  cursor?: string | null
  /**
   *
   * @type {boolean}
   * @memberof PublicGetCapabilitiesPaginatedOutput
   */
  has_more: boolean
  /**
   *
   * @type {Array<PublicGetCapabilitiesPaginatedOutputResultsInner>}
   * @memberof PublicGetCapabilitiesPaginatedOutput
   */
  results: Array<PublicGetCapabilitiesPaginatedOutputResultsInner>
  /**
   *
   * @type {number}
   * @memberof PublicGetCapabilitiesPaginatedOutput
   */
  total_results: number
}
/**
 *
 * @export
 * @interface PublicGetCapabilitiesPaginatedOutputResultsInner
 */
export interface PublicGetCapabilitiesPaginatedOutputResultsInner {
  /**
   *
   * @type {Array<object>}
   * @memberof PublicGetCapabilitiesPaginatedOutputResultsInner
   */
  abi: Array<object>
  /**
   *
   * @type {string}
   * @memberof PublicGetCapabilitiesPaginatedOutputResultsInner
   */
  name: string
}
/**
 *
 * @export
 * @interface PublicGetContractsPaginatedOutput
 */
export interface PublicGetContractsPaginatedOutput {
  /**
   *
   * @type {string}
   * @memberof PublicGetContractsPaginatedOutput
   */
  cursor?: string | null
  /**
   *
   * @type {boolean}
   * @memberof PublicGetContractsPaginatedOutput
   */
  has_more: boolean
  /**
   *
   * @type {Array<PublicGetContractsPaginatedOutputResultsInner>}
   * @memberof PublicGetContractsPaginatedOutput
   */
  results: Array<PublicGetContractsPaginatedOutputResultsInner>
  /**
   *
   * @type {number}
   * @memberof PublicGetContractsPaginatedOutput
   */
  total_results: number
}
/**
 *
 * @export
 * @interface PublicGetContractsPaginatedOutputResultsInner
 */
export interface PublicGetContractsPaginatedOutputResultsInner {
  /**
   *
   * @type {string}
   * @memberof PublicGetContractsPaginatedOutputResultsInner
   */
  address: string
  /**
   *
   * @type {string}
   * @memberof PublicGetContractsPaginatedOutputResultsInner
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof PublicGetContractsPaginatedOutputResultsInner
   */
  network_id: number
}
/**
 *
 * @export
 * @interface PublicGetItemHistoryPaginatedInput
 */
export interface PublicGetItemHistoryPaginatedInput {
  /**
   * SafeString
   * @type {string}
   * @memberof PublicGetItemHistoryPaginatedInput
   */
  cursor?: string | null
  /**
   *
   * @type {string}
   * @memberof PublicGetItemHistoryPaginatedInput
   */
  event_type?: PublicGetItemHistoryPaginatedInputEventTypeEnum
  /**
   * PaginationLimit
   * @type {number}
   * @memberof PublicGetItemHistoryPaginatedInput
   */
  limit?: number
  /**
   *
   * @type {string}
   * @memberof PublicGetItemHistoryPaginatedInput
   */
  order_direction?: PublicGetItemHistoryPaginatedInputOrderDirectionEnum
}

export const PublicGetItemHistoryPaginatedInputEventTypeEnum = {
  Mint: 'MINT',
  Transfer: 'TRANSFER',
  Burn: 'BURN',
} as const

export type PublicGetItemHistoryPaginatedInputEventTypeEnum =
  (typeof PublicGetItemHistoryPaginatedInputEventTypeEnum)[keyof typeof PublicGetItemHistoryPaginatedInputEventTypeEnum]
export const PublicGetItemHistoryPaginatedInputOrderDirectionEnum = {
  Asc: 'ASC',
  Desc: 'DESC',
} as const

export type PublicGetItemHistoryPaginatedInputOrderDirectionEnum =
  (typeof PublicGetItemHistoryPaginatedInputOrderDirectionEnum)[keyof typeof PublicGetItemHistoryPaginatedInputOrderDirectionEnum]

/**
 *
 * @export
 * @interface PublicGetItemHistoryPaginatedOutput
 */
export interface PublicGetItemHistoryPaginatedOutput {
  /**
   *
   * @type {string}
   * @memberof PublicGetItemHistoryPaginatedOutput
   */
  cursor?: string | null
  /**
   *
   * @type {boolean}
   * @memberof PublicGetItemHistoryPaginatedOutput
   */
  has_more: boolean
  /**
   *
   * @type {Array<PublicGetItemHistoryPaginatedOutputResultsInner>}
   * @memberof PublicGetItemHistoryPaginatedOutput
   */
  results: Array<PublicGetItemHistoryPaginatedOutputResultsInner>
  /**
   *
   * @type {number}
   * @memberof PublicGetItemHistoryPaginatedOutput
   */
  total_results: number
}
/**
 *
 * @export
 * @interface PublicGetItemHistoryPaginatedOutputResultsInner
 */
export interface PublicGetItemHistoryPaginatedOutputResultsInner {
  /**
   *
   * @type {object}
   * @memberof PublicGetItemHistoryPaginatedOutputResultsInner
   */
  data: object
  /**
   *
   * @type {number}
   * @memberof PublicGetItemHistoryPaginatedOutputResultsInner
   */
  quantity: number
  /**
   *
   * @type {string}
   * @memberof PublicGetItemHistoryPaginatedOutputResultsInner
   */
  timestamp: string
  /**
   *
   * @type {string}
   * @memberof PublicGetItemHistoryPaginatedOutputResultsInner
   */
  type: PublicGetItemHistoryPaginatedOutputResultsInnerTypeEnum
}

export const PublicGetItemHistoryPaginatedOutputResultsInnerTypeEnum = {
  Mint: 'MINT',
  Transfer: 'TRANSFER',
  Burn: 'BURN',
  AuctionBid: 'AUCTION_BID',
  Sale: 'SALE',
} as const

export type PublicGetItemHistoryPaginatedOutputResultsInnerTypeEnum =
  (typeof PublicGetItemHistoryPaginatedOutputResultsInnerTypeEnum)[keyof typeof PublicGetItemHistoryPaginatedOutputResultsInnerTypeEnum]

/**
 *
 * @export
 * @interface PublicGetPaginatedContractsInput
 */
export interface PublicGetPaginatedContractsInput {
  /**
   * SafeString
   * @type {string}
   * @memberof PublicGetPaginatedContractsInput
   */
  cursor?: string | null
  /**
   * PaginationLimit
   * @type {number}
   * @memberof PublicGetPaginatedContractsInput
   */
  limit?: number
  /**
   *
   * @type {Array<number>}
   * @memberof PublicGetPaginatedContractsInput
   */
  network_ids: Array<number>
  /**
   *
   * @type {string}
   * @memberof PublicGetPaginatedContractsInput
   */
  order_direction?: PublicGetPaginatedContractsInputOrderDirectionEnum
}

export const PublicGetPaginatedContractsInputOrderDirectionEnum = {
  Asc: 'ASC',
  Desc: 'DESC',
} as const

export type PublicGetPaginatedContractsInputOrderDirectionEnum =
  (typeof PublicGetPaginatedContractsInputOrderDirectionEnum)[keyof typeof PublicGetPaginatedContractsInputOrderDirectionEnum]

/**
 *
 * @export
 * @interface PublicItem
 */
export interface PublicItem {
  /**
   *
   * @type {object}
   * @memberof PublicItem
   */
  attributes: object
  /**
   *
   * @type {string}
   * @memberof PublicItem
   */
  collection_id: string
  /**
   *
   * @type {string}
   * @memberof PublicItem
   */
  id: string
  /**
   *
   * @type {GetClaimOutputItemListing}
   * @memberof PublicItem
   */
  listing?: GetClaimOutputItemListing | null
  /**
   *
   * @type {GetClaimOutputItemMedia}
   * @memberof PublicItem
   */
  media: GetClaimOutputItemMedia
  /**
   *
   * @type {GetClaimOutputItemTokenContract}
   * @memberof PublicItem
   */
  token_contract: GetClaimOutputItemTokenContract
  /**
   * Uint256
   * @type {string}
   * @memberof PublicItem
   */
  token_id: string
}
/**
 *
 * @export
 * @interface PublicItemOutput
 */
export interface PublicItemOutput {
  /**
   *
   * @type {object}
   * @memberof PublicItemOutput
   */
  attributes: object
  /**
   *
   * @type {string}
   * @memberof PublicItemOutput
   */
  collection_id: string
  /**
   * ChecksumAddress
   * @type {string}
   * @memberof PublicItemOutput
   */
  contract_address?: string | null
  /**
   *
   * @type {string}
   * @memberof PublicItemOutput
   */
  id: string
  /**
   *
   * @type {GetClaimOutputItemMedia}
   * @memberof PublicItemOutput
   */
  media: GetClaimOutputItemMedia
  /**
   *
   * @type {number}
   * @memberof PublicItemOutput
   */
  network_id: number
  /**
   * Uint256
   * @type {string}
   * @memberof PublicItemOutput
   */
  token_id: string
  /**
   *
   * @type {string}
   * @memberof PublicItemOutput
   */
  token_type: PublicItemOutputTokenTypeEnum
}

export const PublicItemOutputTokenTypeEnum = {
  Erc721: 'ERC721',
  Erc1155: 'ERC1155',
} as const

export type PublicItemOutputTokenTypeEnum =
  (typeof PublicItemOutputTokenTypeEnum)[keyof typeof PublicItemOutputTokenTypeEnum]

/**
 *
 * @export
 * @interface PublicItemOwnersPaginatedInput
 */
export interface PublicItemOwnersPaginatedInput {
  /**
   * SafeString
   * @type {string}
   * @memberof PublicItemOwnersPaginatedInput
   */
  cursor?: string | null
  /**
   * PaginationLimit
   * @type {number}
   * @memberof PublicItemOwnersPaginatedInput
   */
  limit?: number
  /**
   *
   * @type {string}
   * @memberof PublicItemOwnersPaginatedInput
   */
  order_direction?: PublicItemOwnersPaginatedInputOrderDirectionEnum
}

export const PublicItemOwnersPaginatedInputOrderDirectionEnum = {
  Asc: 'ASC',
  Desc: 'DESC',
} as const

export type PublicItemOwnersPaginatedInputOrderDirectionEnum =
  (typeof PublicItemOwnersPaginatedInputOrderDirectionEnum)[keyof typeof PublicItemOwnersPaginatedInputOrderDirectionEnum]

/**
 *
 * @export
 * @interface PublicItemOwnersPaginatedOutput
 */
export interface PublicItemOwnersPaginatedOutput {
  /**
   *
   * @type {string}
   * @memberof PublicItemOwnersPaginatedOutput
   */
  cursor?: string | null
  /**
   *
   * @type {boolean}
   * @memberof PublicItemOwnersPaginatedOutput
   */
  has_more: boolean
  /**
   *
   * @type {Array<PublicItemOwnersPaginatedOutputResultsInner>}
   * @memberof PublicItemOwnersPaginatedOutput
   */
  results: Array<PublicItemOwnersPaginatedOutputResultsInner>
  /**
   *
   * @type {number}
   * @memberof PublicItemOwnersPaginatedOutput
   */
  total_results: number
}
/**
 *
 * @export
 * @interface PublicItemOwnersPaginatedOutputResultsInner
 */
export interface PublicItemOwnersPaginatedOutputResultsInner {
  /**
   * ChecksumAddress
   * @type {string}
   * @memberof PublicItemOwnersPaginatedOutputResultsInner
   */
  address: string
  /**
   *
   * @type {number}
   * @memberof PublicItemOwnersPaginatedOutputResultsInner
   */
  quantity: number
}
/**
 *
 * @export
 * @interface PublicListingOutput
 */
export interface PublicListingOutput {
  /**
   *
   * @type {string}
   * @memberof PublicListingOutput
   */
  collection_id?: string | null
  /**
   * ChecksumAddress
   * @type {string}
   * @memberof PublicListingOutput
   */
  contract: string
  /**
   *
   * @type {string}
   * @memberof PublicListingOutput
   */
  currency: PublicListingOutputCurrencyEnum
  /**
   *
   * @type {string}
   * @memberof PublicListingOutput
   */
  end_time?: string | null
  /**
   *
   * @type {string}
   * @memberof PublicListingOutput
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof PublicListingOutput
   */
  item_id?: string | null
  /**
   *
   * @type {number}
   * @memberof PublicListingOutput
   */
  max_quantity_per_tx: number
  /**
   *
   * @type {number}
   * @memberof PublicListingOutput
   */
  network_id: number
  /**
   *
   * @type {Array<string>}
   * @memberof PublicListingOutput
   */
  payment_providers: Array<PublicListingOutputPaymentProvidersEnum>
  /**
   * Uint256
   * @type {string}
   * @memberof PublicListingOutput
   */
  price: string
  /**
   *
   * @type {number}
   * @memberof PublicListingOutput
   */
  quantity_listed: number
  /**
   *
   * @type {number}
   * @memberof PublicListingOutput
   */
  quantity_remaining: number
  /**
   *
   * @type {string}
   * @memberof PublicListingOutput
   */
  start_time?: string | null
}

export const PublicListingOutputCurrencyEnum = {
  Usd: 'USD',
  Eth: 'ETH',
  Erc20: 'ERC20',
  Eur: 'EUR',
} as const

export type PublicListingOutputCurrencyEnum =
  (typeof PublicListingOutputCurrencyEnum)[keyof typeof PublicListingOutputCurrencyEnum]
export const PublicListingOutputPaymentProvidersEnum = {
  Stripe: 'STRIPE',
  Coinbase: 'COINBASE',
  MintVoucher: 'MINT_VOUCHER',
  EmailClaim: 'EMAIL_CLAIM',
} as const

export type PublicListingOutputPaymentProvidersEnum =
  (typeof PublicListingOutputPaymentProvidersEnum)[keyof typeof PublicListingOutputPaymentProvidersEnum]

/**
 *
 * @export
 * @interface PublicListingsPaginatedInput
 */
export interface PublicListingsPaginatedInput {
  /**
   *
   * @type {string}
   * @memberof PublicListingsPaginatedInput
   */
  collection_id?: string | null
  /**
   * SafeString
   * @type {string}
   * @memberof PublicListingsPaginatedInput
   */
  cursor?: string | null
  /**
   *
   * @type {string}
   * @memberof PublicListingsPaginatedInput
   */
  item_id?: string | null
  /**
   * MediumPaginationLimit
   * @type {number}
   * @memberof PublicListingsPaginatedInput
   */
  limit?: number
  /**
   *
   * @type {string}
   * @memberof PublicListingsPaginatedInput
   */
  order_direction?: PublicListingsPaginatedInputOrderDirectionEnum
  /**
   *
   * @type {string}
   * @memberof PublicListingsPaginatedInput
   */
  organization_id: string
}

export const PublicListingsPaginatedInputOrderDirectionEnum = {
  Asc: 'ASC',
  Desc: 'DESC',
} as const

export type PublicListingsPaginatedInputOrderDirectionEnum =
  (typeof PublicListingsPaginatedInputOrderDirectionEnum)[keyof typeof PublicListingsPaginatedInputOrderDirectionEnum]

/**
 *
 * @export
 * @interface PublicListingsPaginatedOutput
 */
export interface PublicListingsPaginatedOutput {
  /**
   *
   * @type {string}
   * @memberof PublicListingsPaginatedOutput
   */
  cursor?: string | null
  /**
   *
   * @type {boolean}
   * @memberof PublicListingsPaginatedOutput
   */
  has_more: boolean
  /**
   *
   * @type {Array<PublicListingsPaginatedOutputResultsInner>}
   * @memberof PublicListingsPaginatedOutput
   */
  results: Array<PublicListingsPaginatedOutputResultsInner>
  /**
   *
   * @type {number}
   * @memberof PublicListingsPaginatedOutput
   */
  total_results: number
}
/**
 *
 * @export
 * @interface PublicListingsPaginatedOutputResultsInner
 */
export interface PublicListingsPaginatedOutputResultsInner {
  /**
   *
   * @type {string}
   * @memberof PublicListingsPaginatedOutputResultsInner
   */
  collection_id?: string | null
  /**
   * ChecksumAddress
   * @type {string}
   * @memberof PublicListingsPaginatedOutputResultsInner
   */
  contract: string
  /**
   *
   * @type {string}
   * @memberof PublicListingsPaginatedOutputResultsInner
   */
  currency: PublicListingsPaginatedOutputResultsInnerCurrencyEnum
  /**
   *
   * @type {string}
   * @memberof PublicListingsPaginatedOutputResultsInner
   */
  end_time?: string | null
  /**
   *
   * @type {string}
   * @memberof PublicListingsPaginatedOutputResultsInner
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof PublicListingsPaginatedOutputResultsInner
   */
  item_id?: string | null
  /**
   *
   * @type {number}
   * @memberof PublicListingsPaginatedOutputResultsInner
   */
  max_quantity_per_tx: number
  /**
   *
   * @type {number}
   * @memberof PublicListingsPaginatedOutputResultsInner
   */
  network_id: number
  /**
   *
   * @type {Array<string>}
   * @memberof PublicListingsPaginatedOutputResultsInner
   */
  payment_providers: Array<PublicListingsPaginatedOutputResultsInnerPaymentProvidersEnum>
  /**
   * Uint256
   * @type {string}
   * @memberof PublicListingsPaginatedOutputResultsInner
   */
  price: string
  /**
   *
   * @type {number}
   * @memberof PublicListingsPaginatedOutputResultsInner
   */
  quantity_listed: number
  /**
   *
   * @type {number}
   * @memberof PublicListingsPaginatedOutputResultsInner
   */
  quantity_remaining: number
  /**
   *
   * @type {string}
   * @memberof PublicListingsPaginatedOutputResultsInner
   */
  start_time?: string | null
}

export const PublicListingsPaginatedOutputResultsInnerCurrencyEnum = {
  Usd: 'USD',
  Eth: 'ETH',
  Erc20: 'ERC20',
  Eur: 'EUR',
} as const

export type PublicListingsPaginatedOutputResultsInnerCurrencyEnum =
  (typeof PublicListingsPaginatedOutputResultsInnerCurrencyEnum)[keyof typeof PublicListingsPaginatedOutputResultsInnerCurrencyEnum]
export const PublicListingsPaginatedOutputResultsInnerPaymentProvidersEnum = {
  Stripe: 'STRIPE',
  Coinbase: 'COINBASE',
  MintVoucher: 'MINT_VOUCHER',
  EmailClaim: 'EMAIL_CLAIM',
} as const

export type PublicListingsPaginatedOutputResultsInnerPaymentProvidersEnum =
  (typeof PublicListingsPaginatedOutputResultsInnerPaymentProvidersEnum)[keyof typeof PublicListingsPaginatedOutputResultsInnerPaymentProvidersEnum]

/**
 *
 * @export
 * @interface PublicNetworkPaymentTokensPaginatedInput
 */
export interface PublicNetworkPaymentTokensPaginatedInput {
  /**
   * SafeString
   * @type {string}
   * @memberof PublicNetworkPaymentTokensPaginatedInput
   */
  cursor?: string | null
  /**
   * PaginationLimit
   * @type {number}
   * @memberof PublicNetworkPaymentTokensPaginatedInput
   */
  limit?: number
  /**
   *
   * @type {Array<number>}
   * @memberof PublicNetworkPaymentTokensPaginatedInput
   */
  network_id: Array<number>
  /**
   *
   * @type {string}
   * @memberof PublicNetworkPaymentTokensPaginatedInput
   */
  order_direction?: PublicNetworkPaymentTokensPaginatedInputOrderDirectionEnum
}

export const PublicNetworkPaymentTokensPaginatedInputOrderDirectionEnum = {
  Asc: 'ASC',
  Desc: 'DESC',
} as const

export type PublicNetworkPaymentTokensPaginatedInputOrderDirectionEnum =
  (typeof PublicNetworkPaymentTokensPaginatedInputOrderDirectionEnum)[keyof typeof PublicNetworkPaymentTokensPaginatedInputOrderDirectionEnum]

/**
 *
 * @export
 * @interface PublicNetworkPaymentTokensPaginatedOutput
 */
export interface PublicNetworkPaymentTokensPaginatedOutput {
  /**
   *
   * @type {string}
   * @memberof PublicNetworkPaymentTokensPaginatedOutput
   */
  cursor?: string | null
  /**
   *
   * @type {boolean}
   * @memberof PublicNetworkPaymentTokensPaginatedOutput
   */
  has_more: boolean
  /**
   *
   * @type {Array<PublicNetworkPaymentTokensPaginatedOutputResultsInner>}
   * @memberof PublicNetworkPaymentTokensPaginatedOutput
   */
  results: Array<PublicNetworkPaymentTokensPaginatedOutputResultsInner>
  /**
   *
   * @type {number}
   * @memberof PublicNetworkPaymentTokensPaginatedOutput
   */
  total_results: number
}
/**
 *
 * @export
 * @interface PublicNetworkPaymentTokensPaginatedOutputResultsInner
 */
export interface PublicNetworkPaymentTokensPaginatedOutputResultsInner {
  /**
   * ChecksumAddress
   * @type {string}
   * @memberof PublicNetworkPaymentTokensPaginatedOutputResultsInner
   */
  address: string
  /**
   *
   * @type {number}
   * @memberof PublicNetworkPaymentTokensPaginatedOutputResultsInner
   */
  decimals: number
  /**
   *
   * @type {boolean}
   * @memberof PublicNetworkPaymentTokensPaginatedOutputResultsInner
   */
  eth?: boolean
  /**
   *
   * @type {boolean}
   * @memberof PublicNetworkPaymentTokensPaginatedOutputResultsInner
   */
  eur_stablecoin?: boolean
  /**
   *
   * @type {number}
   * @memberof PublicNetworkPaymentTokensPaginatedOutputResultsInner
   */
  network_id: number
  /**
   *
   * @type {string}
   * @memberof PublicNetworkPaymentTokensPaginatedOutputResultsInner
   */
  symbol: string
  /**
   *
   * @type {boolean}
   * @memberof PublicNetworkPaymentTokensPaginatedOutputResultsInner
   */
  usd_stablecoin?: boolean
}
/**
 *
 * @export
 * @interface PublicNetworksPaginatedInput
 */
export interface PublicNetworksPaginatedInput {
  /**
   * SafeString
   * @type {string}
   * @memberof PublicNetworksPaginatedInput
   */
  cursor?: string | null
  /**
   * PaginationLimit
   * @type {number}
   * @memberof PublicNetworksPaginatedInput
   */
  limit?: number
  /**
   *
   * @type {string}
   * @memberof PublicNetworksPaginatedInput
   */
  order_direction?: PublicNetworksPaginatedInputOrderDirectionEnum
}

export const PublicNetworksPaginatedInputOrderDirectionEnum = {
  Asc: 'ASC',
  Desc: 'DESC',
} as const

export type PublicNetworksPaginatedInputOrderDirectionEnum =
  (typeof PublicNetworksPaginatedInputOrderDirectionEnum)[keyof typeof PublicNetworksPaginatedInputOrderDirectionEnum]

/**
 *
 * @export
 * @interface PublicNetworksPaginatedOutput
 */
export interface PublicNetworksPaginatedOutput {
  /**
   *
   * @type {string}
   * @memberof PublicNetworksPaginatedOutput
   */
  cursor?: string | null
  /**
   *
   * @type {boolean}
   * @memberof PublicNetworksPaginatedOutput
   */
  has_more: boolean
  /**
   *
   * @type {Array<PublicNetworksPaginatedOutputResultsInner>}
   * @memberof PublicNetworksPaginatedOutput
   */
  results: Array<PublicNetworksPaginatedOutputResultsInner>
  /**
   *
   * @type {number}
   * @memberof PublicNetworksPaginatedOutput
   */
  total_results: number
}
/**
 *
 * @export
 * @interface PublicNetworksPaginatedOutputResultsInner
 */
export interface PublicNetworksPaginatedOutputResultsInner {
  /**
   *
   * @type {number}
   * @memberof PublicNetworksPaginatedOutputResultsInner
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof PublicNetworksPaginatedOutputResultsInner
   */
  name: string
  /**
   *
   * @type {boolean}
   * @memberof PublicNetworksPaginatedOutputResultsInner
   */
  testnet: boolean
}
/**
 *
 * @export
 * @interface RedeemEmailClaimOutput
 */
export interface RedeemEmailClaimOutput {
  /**
   *
   * @type {string}
   * @memberof RedeemEmailClaimOutput
   */
  status: string
  /**
   *
   * @type {GetClaimOutputVoucher}
   * @memberof RedeemEmailClaimOutput
   */
  voucher?: GetClaimOutputVoucher | null
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get the Application Binary Interface (ABI) for a specific capability
     * @param {string} capability
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1CapabilitiesCapabilityGet: async (
      capability: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'capability' is not null or undefined
      assertParamExists('publicV1CapabilitiesCapabilityGet', 'capability', capability)
      const localVarPath = `/v1/capabilities/{capability}`.replace(
        `{${'capability'}}`,
        encodeURIComponent(String(capability)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get known contract capabilities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1CapabilitiesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/capabilities`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get a public collection by collection id
     * @param {string} collectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1CollectionsCollectionIdGet: async (
      collectionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'collectionId' is not null or undefined
      assertParamExists('publicV1CollectionsCollectionIdGet', 'collectionId', collectionId)
      const localVarPath = `/v1/collections/{collection_id}`.replace(
        `{${'collection_id'}}`,
        encodeURIComponent(String(collectionId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get collection stats
     * @param {string} collectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1CollectionsCollectionIdStatsGet: async (
      collectionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'collectionId' is not null or undefined
      assertParamExists('publicV1CollectionsCollectionIdStatsGet', 'collectionId', collectionId)
      const localVarPath = `/v1/collections/{collection_id}/stats`.replace(
        `{${'collection_id'}}`,
        encodeURIComponent(String(collectionId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get all collections by an organization
     * @param {string} organizationId
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1CollectionsGet: async (
      organizationId: string,
      orderDirection?: 'ASC' | 'DESC' | 'null',
      cursor?: string,
      limit?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists('publicV1CollectionsGet', 'organizationId', organizationId)
      const localVarPath = `/v1/collections`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (orderDirection !== undefined) {
        localVarQueryParameter['order_direction'] = orderDirection
      }

      if (cursor !== undefined) {
        localVarQueryParameter['cursor'] = cursor
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (organizationId !== undefined) {
        localVarQueryParameter['organization_id'] = organizationId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get global contract addresses.
     * @param {Array<number>} networkIds
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1ContractsGet: async (
      networkIds: Array<number>,
      orderDirection?: 'ASC' | 'DESC' | 'null',
      cursor?: string,
      limit?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'networkIds' is not null or undefined
      assertParamExists('publicV1ContractsGet', 'networkIds', networkIds)
      const localVarPath = `/v1/contracts`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (orderDirection !== undefined) {
        localVarQueryParameter['order_direction'] = orderDirection
      }

      if (cursor !== undefined) {
        localVarQueryParameter['cursor'] = cursor
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (networkIds) {
        localVarQueryParameter['network_ids'] = networkIds
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get the item that is redeemable for the given claim token
     * @param {string} claimToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1EmailClaimsClaimTokenGet: async (
      claimToken: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'claimToken' is not null or undefined
      assertParamExists('publicV1EmailClaimsClaimTokenGet', 'claimToken', claimToken)
      const localVarPath = `/v1/email-claims/{claim_token}`.replace(
        `{${'claim_token'}}`,
        encodeURIComponent(String(claimToken)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * If the tx_payer is SELLER, the token is immediately transferred to the buyer. If the tx_payer is BUYER, a mint voucher is returned.
     * @summary Redeems an email claim based on the claim token and pincode
     * @param {string} claimToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1EmailClaimsClaimTokenRedeemPost: async (
      claimToken: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'claimToken' is not null or undefined
      assertParamExists('publicV1EmailClaimsClaimTokenRedeemPost', 'claimToken', claimToken)
      const localVarPath = `/v1/email-claims/{claim_token}/redeem`.replace(
        `{${'claim_token'}}`,
        encodeURIComponent(String(claimToken)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Sends a pincode to the email address to verify the claim
     * @param {string} claimToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1EmailClaimsClaimTokenVerifyPost: async (
      claimToken: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'claimToken' is not null or undefined
      assertParamExists('publicV1EmailClaimsClaimTokenVerifyPost', 'claimToken', claimToken)
      const localVarPath = `/v1/email-claims/{claim_token}/verify`.replace(
        `{${'claim_token'}}`,
        encodeURIComponent(String(claimToken)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get all public items from an organization
     * @param {string} organizationId
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
     * @param {string} [cursor] SafeString
     * @param {number} [limit] MediumPaginationLimit
     * @param {Array<number>} [networkIds]
     * @param {Array<string>} [attributes]
     * @param {Array<string>} [collectionIds]
     * @param {Array<number>} [tokenIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1ItemsGet: async (
      organizationId: string,
      orderDirection?: 'ASC' | 'DESC' | 'null',
      cursor?: string,
      limit?: number,
      networkIds?: Array<number>,
      attributes?: Array<string>,
      collectionIds?: Array<string>,
      tokenIds?: Array<number>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists('publicV1ItemsGet', 'organizationId', organizationId)
      const localVarPath = `/v1/items`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (orderDirection !== undefined) {
        localVarQueryParameter['order_direction'] = orderDirection
      }

      if (cursor !== undefined) {
        localVarQueryParameter['cursor'] = cursor
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (organizationId !== undefined) {
        localVarQueryParameter['organization_id'] = organizationId
      }

      if (networkIds) {
        localVarQueryParameter['network_ids'] = networkIds
      }

      if (attributes) {
        localVarQueryParameter['attributes'] = attributes
      }

      if (collectionIds) {
        localVarQueryParameter['collection_ids'] = collectionIds
      }

      if (tokenIds) {
        localVarQueryParameter['token_ids'] = tokenIds
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get a public item by id
     * @param {string} itemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1ItemsItemIdGet: async (
      itemId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'itemId' is not null or undefined
      assertParamExists('publicV1ItemsItemIdGet', 'itemId', itemId)
      const localVarPath = `/v1/items/{item_id}`.replace(
        `{${'item_id'}}`,
        encodeURIComponent(String(itemId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get item history
     * @param {string} itemId
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {'MINT' | 'TRANSFER' | 'BURN' | 'null'} [eventType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1ItemsItemIdHistoryGet: async (
      itemId: string,
      orderDirection?: 'ASC' | 'DESC' | 'null',
      cursor?: string,
      limit?: number,
      eventType?: 'MINT' | 'TRANSFER' | 'BURN' | 'null',
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'itemId' is not null or undefined
      assertParamExists('publicV1ItemsItemIdHistoryGet', 'itemId', itemId)
      const localVarPath = `/v1/items/{item_id}/history`.replace(
        `{${'item_id'}}`,
        encodeURIComponent(String(itemId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (orderDirection !== undefined) {
        localVarQueryParameter['order_direction'] = orderDirection
      }

      if (cursor !== undefined) {
        localVarQueryParameter['cursor'] = cursor
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (eventType !== undefined) {
        localVarQueryParameter['event_type'] = eventType
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get item owners.
     * @param {string} itemId
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1ItemsItemIdOwnersGet: async (
      itemId: string,
      orderDirection?: 'ASC' | 'DESC' | 'null',
      cursor?: string,
      limit?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'itemId' is not null or undefined
      assertParamExists('publicV1ItemsItemIdOwnersGet', 'itemId', itemId)
      const localVarPath = `/v1/items/{item_id}/owners`.replace(
        `{${'item_id'}}`,
        encodeURIComponent(String(itemId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (orderDirection !== undefined) {
        localVarQueryParameter['order_direction'] = orderDirection
      }

      if (cursor !== undefined) {
        localVarQueryParameter['cursor'] = cursor
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get listings for the given organization
     * @param {string} organizationId
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
     * @param {string} [cursor] SafeString
     * @param {number} [limit] MediumPaginationLimit
     * @param {string} [itemId]
     * @param {string} [collectionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1ListingsGet: async (
      organizationId: string,
      orderDirection?: 'ASC' | 'DESC' | 'null',
      cursor?: string,
      limit?: number,
      itemId?: string,
      collectionId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists('publicV1ListingsGet', 'organizationId', organizationId)
      const localVarPath = `/v1/listings`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (orderDirection !== undefined) {
        localVarQueryParameter['order_direction'] = orderDirection
      }

      if (cursor !== undefined) {
        localVarQueryParameter['cursor'] = cursor
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (organizationId !== undefined) {
        localVarQueryParameter['organization_id'] = organizationId
      }

      if (itemId !== undefined) {
        localVarQueryParameter['item_id'] = itemId
      }

      if (collectionId !== undefined) {
        localVarQueryParameter['collection_id'] = collectionId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Listing for the given listing id
     * @param {string} listingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1ListingsListingIdGet: async (
      listingId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'listingId' is not null or undefined
      assertParamExists('publicV1ListingsListingIdGet', 'listingId', listingId)
      const localVarPath = `/v1/listings/{listing_id}`.replace(
        `{${'listing_id'}}`,
        encodeURIComponent(String(listingId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get metadata for a specific token in a collection
     * @param {string} collectionId
     * @param {string} tokenId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1MetadataCollectionIdTokenIdGet: async (
      collectionId: string,
      tokenId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'collectionId' is not null or undefined
      assertParamExists('publicV1MetadataCollectionIdTokenIdGet', 'collectionId', collectionId)
      // verify required parameter 'tokenId' is not null or undefined
      assertParamExists('publicV1MetadataCollectionIdTokenIdGet', 'tokenId', tokenId)
      const localVarPath = `/v1/metadata/{collection_id}/{token_id}`
        .replace(`{${'collection_id'}}`, encodeURIComponent(String(collectionId)))
        .replace(`{${'token_id'}}`, encodeURIComponent(String(tokenId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get the pending mint vouchers for the ethereum address
     * @param {string} ethAddress
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
     * @param {string} [cursor] SafeString
     * @param {number} [limit] MediumPaginationLimit
     * @param {string} [contractAddress] ChecksumAddress
     * @param {number} [networkId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1MintVouchersEthAddressGet: async (
      ethAddress: string,
      orderDirection?: 'ASC' | 'DESC' | 'null',
      cursor?: string,
      limit?: number,
      contractAddress?: string,
      networkId?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'ethAddress' is not null or undefined
      assertParamExists('publicV1MintVouchersEthAddressGet', 'ethAddress', ethAddress)
      const localVarPath = `/v1/mint-vouchers/{eth_address}`.replace(
        `{${'eth_address'}}`,
        encodeURIComponent(String(ethAddress)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (orderDirection !== undefined) {
        localVarQueryParameter['order_direction'] = orderDirection
      }

      if (cursor !== undefined) {
        localVarQueryParameter['cursor'] = cursor
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (contractAddress !== undefined) {
        localVarQueryParameter['contract_address'] = contractAddress
      }

      if (networkId !== undefined) {
        localVarQueryParameter['network_id'] = networkId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get the available networks
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1NetworksGet: async (
      orderDirection?: 'ASC' | 'DESC' | 'null',
      cursor?: string,
      limit?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/networks`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (orderDirection !== undefined) {
        localVarQueryParameter['order_direction'] = orderDirection
      }

      if (cursor !== undefined) {
        localVarQueryParameter['cursor'] = cursor
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * The purchase intent can be made with any provider configured for the listing. The provider must be one of the following: STRIPE, COINBASE. The response data will have provider specific data in the data field.
     * @summary Create a purchase intent
     * @param {CreatePurchaseIntentInput} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1PurchaseIntentsPost: async (
      body?: CreatePurchaseIntentInput,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/purchase-intents`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Request a cancel of a purchase intent for a given purchase intent id.
     * @param {string} purchaseIntentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1PurchaseIntentsPurchaseIntentIdDelete: async (
      purchaseIntentId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'purchaseIntentId' is not null or undefined
      assertParamExists(
        'publicV1PurchaseIntentsPurchaseIntentIdDelete',
        'purchaseIntentId',
        purchaseIntentId,
      )
      const localVarPath = `/v1/purchase-intents/{purchase_intent_id}`.replace(
        `{${'purchase_intent_id'}}`,
        encodeURIComponent(String(purchaseIntentId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get payment tokens available for the given networks
     * @param {Array<number>} networkId
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1TokensPaymentTokensGet: async (
      networkId: Array<number>,
      orderDirection?: 'ASC' | 'DESC' | 'null',
      cursor?: string,
      limit?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'networkId' is not null or undefined
      assertParamExists('publicV1TokensPaymentTokensGet', 'networkId', networkId)
      const localVarPath = `/v1/tokens/payment-tokens`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (orderDirection !== undefined) {
        localVarQueryParameter['order_direction'] = orderDirection
      }

      if (cursor !== undefined) {
        localVarQueryParameter['cursor'] = cursor
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (networkId) {
        localVarQueryParameter['network_id'] = networkId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Get the Application Binary Interface (ABI) for a specific capability
     * @param {string} capability
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publicV1CapabilitiesCapabilityGet(
      capability: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicGetCapabilitiesOutput>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.publicV1CapabilitiesCapabilityGet(
        capability,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get known contract capabilities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publicV1CapabilitiesGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PublicGetCapabilitiesPaginatedOutput>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.publicV1CapabilitiesGet(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get a public collection by collection id
     * @param {string} collectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publicV1CollectionsCollectionIdGet(
      collectionId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicCollectionOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.publicV1CollectionsCollectionIdGet(
        collectionId,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get collection stats
     * @param {string} collectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publicV1CollectionsCollectionIdStatsGet(
      collectionId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionStats>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.publicV1CollectionsCollectionIdStatsGet(
          collectionId,
          options,
        )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get all collections by an organization
     * @param {string} organizationId
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publicV1CollectionsGet(
      organizationId: string,
      orderDirection?: 'ASC' | 'DESC' | 'null',
      cursor?: string,
      limit?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicCollectionsPaginatedOutput>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.publicV1CollectionsGet(
        organizationId,
        orderDirection,
        cursor,
        limit,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get global contract addresses.
     * @param {Array<number>} networkIds
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publicV1ContractsGet(
      networkIds: Array<number>,
      orderDirection?: 'ASC' | 'DESC' | 'null',
      cursor?: string,
      limit?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicGetContractsPaginatedOutput>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.publicV1ContractsGet(
        networkIds,
        orderDirection,
        cursor,
        limit,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get the item that is redeemable for the given claim token
     * @param {string} claimToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publicV1EmailClaimsClaimTokenGet(
      claimToken: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClaimOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.publicV1EmailClaimsClaimTokenGet(
        claimToken,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * If the tx_payer is SELLER, the token is immediately transferred to the buyer. If the tx_payer is BUYER, a mint voucher is returned.
     * @summary Redeems an email claim based on the claim token and pincode
     * @param {string} claimToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publicV1EmailClaimsClaimTokenRedeemPost(
      claimToken: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RedeemEmailClaimOutput>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.publicV1EmailClaimsClaimTokenRedeemPost(claimToken, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Sends a pincode to the email address to verify the claim
     * @param {string} claimToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publicV1EmailClaimsClaimTokenVerifyPost(
      claimToken: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicItem>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.publicV1EmailClaimsClaimTokenVerifyPost(claimToken, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get all public items from an organization
     * @param {string} organizationId
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
     * @param {string} [cursor] SafeString
     * @param {number} [limit] MediumPaginationLimit
     * @param {Array<number>} [networkIds]
     * @param {Array<string>} [attributes]
     * @param {Array<string>} [collectionIds]
     * @param {Array<number>} [tokenIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publicV1ItemsGet(
      organizationId: string,
      orderDirection?: 'ASC' | 'DESC' | 'null',
      cursor?: string,
      limit?: number,
      networkIds?: Array<number>,
      attributes?: Array<string>,
      collectionIds?: Array<string>,
      tokenIds?: Array<number>,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPublicOrgItemsPaginatedOutput>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.publicV1ItemsGet(
        organizationId,
        orderDirection,
        cursor,
        limit,
        networkIds,
        attributes,
        collectionIds,
        tokenIds,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get a public item by id
     * @param {string} itemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publicV1ItemsItemIdGet(
      itemId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicItemOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.publicV1ItemsItemIdGet(
        itemId,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get item history
     * @param {string} itemId
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {'MINT' | 'TRANSFER' | 'BURN' | 'null'} [eventType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publicV1ItemsItemIdHistoryGet(
      itemId: string,
      orderDirection?: 'ASC' | 'DESC' | 'null',
      cursor?: string,
      limit?: number,
      eventType?: 'MINT' | 'TRANSFER' | 'BURN' | 'null',
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PublicGetItemHistoryPaginatedOutput>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.publicV1ItemsItemIdHistoryGet(
        itemId,
        orderDirection,
        cursor,
        limit,
        eventType,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get item owners.
     * @param {string} itemId
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publicV1ItemsItemIdOwnersGet(
      itemId: string,
      orderDirection?: 'ASC' | 'DESC' | 'null',
      cursor?: string,
      limit?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicItemOwnersPaginatedOutput>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.publicV1ItemsItemIdOwnersGet(
        itemId,
        orderDirection,
        cursor,
        limit,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get listings for the given organization
     * @param {string} organizationId
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
     * @param {string} [cursor] SafeString
     * @param {number} [limit] MediumPaginationLimit
     * @param {string} [itemId]
     * @param {string} [collectionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publicV1ListingsGet(
      organizationId: string,
      orderDirection?: 'ASC' | 'DESC' | 'null',
      cursor?: string,
      limit?: number,
      itemId?: string,
      collectionId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicListingsPaginatedOutput>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.publicV1ListingsGet(
        organizationId,
        orderDirection,
        cursor,
        limit,
        itemId,
        collectionId,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get Listing for the given listing id
     * @param {string} listingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publicV1ListingsListingIdGet(
      listingId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicListingOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.publicV1ListingsListingIdGet(
        listingId,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get metadata for a specific token in a collection
     * @param {string} collectionId
     * @param {string} tokenId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publicV1MetadataCollectionIdTokenIdGet(
      collectionId: string,
      tokenId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.publicV1MetadataCollectionIdTokenIdGet(
          collectionId,
          tokenId,
          options,
        )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get the pending mint vouchers for the ethereum address
     * @param {string} ethAddress
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
     * @param {string} [cursor] SafeString
     * @param {number} [limit] MediumPaginationLimit
     * @param {string} [contractAddress] ChecksumAddress
     * @param {number} [networkId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publicV1MintVouchersEthAddressGet(
      ethAddress: string,
      orderDirection?: 'ASC' | 'DESC' | 'null',
      cursor?: string,
      limit?: number,
      contractAddress?: string,
      networkId?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVouchersPaginatedOutput>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.publicV1MintVouchersEthAddressGet(
        ethAddress,
        orderDirection,
        cursor,
        limit,
        contractAddress,
        networkId,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get the available networks
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publicV1NetworksGet(
      orderDirection?: 'ASC' | 'DESC' | 'null',
      cursor?: string,
      limit?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicNetworksPaginatedOutput>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.publicV1NetworksGet(
        orderDirection,
        cursor,
        limit,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * The purchase intent can be made with any provider configured for the listing. The provider must be one of the following: STRIPE, COINBASE. The response data will have provider specific data in the data field.
     * @summary Create a purchase intent
     * @param {CreatePurchaseIntentInput} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publicV1PurchaseIntentsPost(
      body?: CreatePurchaseIntentInput,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatePurchaseIntentOutput>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.publicV1PurchaseIntentsPost(
        body,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Request a cancel of a purchase intent for a given purchase intent id.
     * @param {string} purchaseIntentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publicV1PurchaseIntentsPurchaseIntentIdDelete(
      purchaseIntentId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.publicV1PurchaseIntentsPurchaseIntentIdDelete(
          purchaseIntentId,
          options,
        )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get payment tokens available for the given networks
     * @param {Array<number>} networkId
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publicV1TokensPaymentTokensGet(
      networkId: Array<number>,
      orderDirection?: 'ASC' | 'DESC' | 'null',
      cursor?: string,
      limit?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PublicNetworkPaymentTokensPaginatedOutput>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.publicV1TokensPaymentTokensGet(
        networkId,
        orderDirection,
        cursor,
        limit,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DefaultApiFp(configuration)
  return {
    /**
     *
     * @summary Get the Application Binary Interface (ABI) for a specific capability
     * @param {string} capability
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1CapabilitiesCapabilityGet(
      capability: string,
      options?: any,
    ): AxiosPromise<PublicGetCapabilitiesOutput> {
      return localVarFp
        .publicV1CapabilitiesCapabilityGet(capability, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get known contract capabilities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1CapabilitiesGet(options?: any): AxiosPromise<PublicGetCapabilitiesPaginatedOutput> {
      return localVarFp.publicV1CapabilitiesGet(options).then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get a public collection by collection id
     * @param {string} collectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1CollectionsCollectionIdGet(
      collectionId: string,
      options?: any,
    ): AxiosPromise<PublicCollectionOutput> {
      return localVarFp
        .publicV1CollectionsCollectionIdGet(collectionId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get collection stats
     * @param {string} collectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1CollectionsCollectionIdStatsGet(
      collectionId: string,
      options?: any,
    ): AxiosPromise<CollectionStats> {
      return localVarFp
        .publicV1CollectionsCollectionIdStatsGet(collectionId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get all collections by an organization
     * @param {string} organizationId
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1CollectionsGet(
      organizationId: string,
      orderDirection?: 'ASC' | 'DESC' | 'null',
      cursor?: string,
      limit?: number,
      options?: any,
    ): AxiosPromise<PublicCollectionsPaginatedOutput> {
      return localVarFp
        .publicV1CollectionsGet(organizationId, orderDirection, cursor, limit, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get global contract addresses.
     * @param {Array<number>} networkIds
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1ContractsGet(
      networkIds: Array<number>,
      orderDirection?: 'ASC' | 'DESC' | 'null',
      cursor?: string,
      limit?: number,
      options?: any,
    ): AxiosPromise<PublicGetContractsPaginatedOutput> {
      return localVarFp
        .publicV1ContractsGet(networkIds, orderDirection, cursor, limit, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get the item that is redeemable for the given claim token
     * @param {string} claimToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1EmailClaimsClaimTokenGet(
      claimToken: string,
      options?: any,
    ): AxiosPromise<GetClaimOutput> {
      return localVarFp
        .publicV1EmailClaimsClaimTokenGet(claimToken, options)
        .then(request => request(axios, basePath))
    },
    /**
     * If the tx_payer is SELLER, the token is immediately transferred to the buyer. If the tx_payer is BUYER, a mint voucher is returned.
     * @summary Redeems an email claim based on the claim token and pincode
     * @param {string} claimToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1EmailClaimsClaimTokenRedeemPost(
      claimToken: string,
      options?: any,
    ): AxiosPromise<RedeemEmailClaimOutput> {
      return localVarFp
        .publicV1EmailClaimsClaimTokenRedeemPost(claimToken, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Sends a pincode to the email address to verify the claim
     * @param {string} claimToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1EmailClaimsClaimTokenVerifyPost(
      claimToken: string,
      options?: any,
    ): AxiosPromise<PublicItem> {
      return localVarFp
        .publicV1EmailClaimsClaimTokenVerifyPost(claimToken, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get all public items from an organization
     * @param {string} organizationId
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
     * @param {string} [cursor] SafeString
     * @param {number} [limit] MediumPaginationLimit
     * @param {Array<number>} [networkIds]
     * @param {Array<string>} [attributes]
     * @param {Array<string>} [collectionIds]
     * @param {Array<number>} [tokenIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1ItemsGet(
      organizationId: string,
      orderDirection?: 'ASC' | 'DESC' | 'null',
      cursor?: string,
      limit?: number,
      networkIds?: Array<number>,
      attributes?: Array<string>,
      collectionIds?: Array<string>,
      tokenIds?: Array<number>,
      options?: any,
    ): AxiosPromise<GetPublicOrgItemsPaginatedOutput> {
      return localVarFp
        .publicV1ItemsGet(
          organizationId,
          orderDirection,
          cursor,
          limit,
          networkIds,
          attributes,
          collectionIds,
          tokenIds,
          options,
        )
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get a public item by id
     * @param {string} itemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1ItemsItemIdGet(itemId: string, options?: any): AxiosPromise<PublicItemOutput> {
      return localVarFp
        .publicV1ItemsItemIdGet(itemId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get item history
     * @param {string} itemId
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {'MINT' | 'TRANSFER' | 'BURN' | 'null'} [eventType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1ItemsItemIdHistoryGet(
      itemId: string,
      orderDirection?: 'ASC' | 'DESC' | 'null',
      cursor?: string,
      limit?: number,
      eventType?: 'MINT' | 'TRANSFER' | 'BURN' | 'null',
      options?: any,
    ): AxiosPromise<PublicGetItemHistoryPaginatedOutput> {
      return localVarFp
        .publicV1ItemsItemIdHistoryGet(itemId, orderDirection, cursor, limit, eventType, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get item owners.
     * @param {string} itemId
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1ItemsItemIdOwnersGet(
      itemId: string,
      orderDirection?: 'ASC' | 'DESC' | 'null',
      cursor?: string,
      limit?: number,
      options?: any,
    ): AxiosPromise<PublicItemOwnersPaginatedOutput> {
      return localVarFp
        .publicV1ItemsItemIdOwnersGet(itemId, orderDirection, cursor, limit, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get listings for the given organization
     * @param {string} organizationId
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
     * @param {string} [cursor] SafeString
     * @param {number} [limit] MediumPaginationLimit
     * @param {string} [itemId]
     * @param {string} [collectionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1ListingsGet(
      organizationId: string,
      orderDirection?: 'ASC' | 'DESC' | 'null',
      cursor?: string,
      limit?: number,
      itemId?: string,
      collectionId?: string,
      options?: any,
    ): AxiosPromise<PublicListingsPaginatedOutput> {
      return localVarFp
        .publicV1ListingsGet(
          organizationId,
          orderDirection,
          cursor,
          limit,
          itemId,
          collectionId,
          options,
        )
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Listing for the given listing id
     * @param {string} listingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1ListingsListingIdGet(
      listingId: string,
      options?: any,
    ): AxiosPromise<PublicListingOutput> {
      return localVarFp
        .publicV1ListingsListingIdGet(listingId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get metadata for a specific token in a collection
     * @param {string} collectionId
     * @param {string} tokenId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1MetadataCollectionIdTokenIdGet(
      collectionId: string,
      tokenId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .publicV1MetadataCollectionIdTokenIdGet(collectionId, tokenId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get the pending mint vouchers for the ethereum address
     * @param {string} ethAddress
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
     * @param {string} [cursor] SafeString
     * @param {number} [limit] MediumPaginationLimit
     * @param {string} [contractAddress] ChecksumAddress
     * @param {number} [networkId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1MintVouchersEthAddressGet(
      ethAddress: string,
      orderDirection?: 'ASC' | 'DESC' | 'null',
      cursor?: string,
      limit?: number,
      contractAddress?: string,
      networkId?: number,
      options?: any,
    ): AxiosPromise<GetVouchersPaginatedOutput> {
      return localVarFp
        .publicV1MintVouchersEthAddressGet(
          ethAddress,
          orderDirection,
          cursor,
          limit,
          contractAddress,
          networkId,
          options,
        )
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get the available networks
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1NetworksGet(
      orderDirection?: 'ASC' | 'DESC' | 'null',
      cursor?: string,
      limit?: number,
      options?: any,
    ): AxiosPromise<PublicNetworksPaginatedOutput> {
      return localVarFp
        .publicV1NetworksGet(orderDirection, cursor, limit, options)
        .then(request => request(axios, basePath))
    },
    /**
     * The purchase intent can be made with any provider configured for the listing. The provider must be one of the following: STRIPE, COINBASE. The response data will have provider specific data in the data field.
     * @summary Create a purchase intent
     * @param {CreatePurchaseIntentInput} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1PurchaseIntentsPost(
      body?: CreatePurchaseIntentInput,
      options?: any,
    ): AxiosPromise<CreatePurchaseIntentOutput> {
      return localVarFp
        .publicV1PurchaseIntentsPost(body, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Request a cancel of a purchase intent for a given purchase intent id.
     * @param {string} purchaseIntentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1PurchaseIntentsPurchaseIntentIdDelete(
      purchaseIntentId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .publicV1PurchaseIntentsPurchaseIntentIdDelete(purchaseIntentId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get payment tokens available for the given networks
     * @param {Array<number>} networkId
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publicV1TokensPaymentTokensGet(
      networkId: Array<number>,
      orderDirection?: 'ASC' | 'DESC' | 'null',
      cursor?: string,
      limit?: number,
      options?: any,
    ): AxiosPromise<PublicNetworkPaymentTokensPaginatedOutput> {
      return localVarFp
        .publicV1TokensPaymentTokensGet(networkId, orderDirection, cursor, limit, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary Get the Application Binary Interface (ABI) for a specific capability
   * @param {string} capability
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public publicV1CapabilitiesCapabilityGet(capability: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .publicV1CapabilitiesCapabilityGet(capability, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get known contract capabilities
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public publicV1CapabilitiesGet(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .publicV1CapabilitiesGet(options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get a public collection by collection id
   * @param {string} collectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public publicV1CollectionsCollectionIdGet(collectionId: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .publicV1CollectionsCollectionIdGet(collectionId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get collection stats
   * @param {string} collectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public publicV1CollectionsCollectionIdStatsGet(
    collectionId: string,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .publicV1CollectionsCollectionIdStatsGet(collectionId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get all collections by an organization
   * @param {string} organizationId
   * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
   * @param {string} [cursor] SafeString
   * @param {number} [limit] PaginationLimit
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public publicV1CollectionsGet(
    organizationId: string,
    orderDirection?: 'ASC' | 'DESC' | 'null',
    cursor?: string,
    limit?: number,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .publicV1CollectionsGet(organizationId, orderDirection, cursor, limit, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get global contract addresses.
   * @param {Array<number>} networkIds
   * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
   * @param {string} [cursor] SafeString
   * @param {number} [limit] PaginationLimit
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public publicV1ContractsGet(
    networkIds: Array<number>,
    orderDirection?: 'ASC' | 'DESC' | 'null',
    cursor?: string,
    limit?: number,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .publicV1ContractsGet(networkIds, orderDirection, cursor, limit, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get the item that is redeemable for the given claim token
   * @param {string} claimToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public publicV1EmailClaimsClaimTokenGet(claimToken: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .publicV1EmailClaimsClaimTokenGet(claimToken, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   * If the tx_payer is SELLER, the token is immediately transferred to the buyer. If the tx_payer is BUYER, a mint voucher is returned.
   * @summary Redeems an email claim based on the claim token and pincode
   * @param {string} claimToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public publicV1EmailClaimsClaimTokenRedeemPost(claimToken: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .publicV1EmailClaimsClaimTokenRedeemPost(claimToken, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Sends a pincode to the email address to verify the claim
   * @param {string} claimToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public publicV1EmailClaimsClaimTokenVerifyPost(claimToken: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .publicV1EmailClaimsClaimTokenVerifyPost(claimToken, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get all public items from an organization
   * @param {string} organizationId
   * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
   * @param {string} [cursor] SafeString
   * @param {number} [limit] MediumPaginationLimit
   * @param {Array<number>} [networkIds]
   * @param {Array<string>} [attributes]
   * @param {Array<string>} [collectionIds]
   * @param {Array<number>} [tokenIds]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public publicV1ItemsGet(
    organizationId: string,
    orderDirection?: 'ASC' | 'DESC' | 'null',
    cursor?: string,
    limit?: number,
    networkIds?: Array<number>,
    attributes?: Array<string>,
    collectionIds?: Array<string>,
    tokenIds?: Array<number>,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .publicV1ItemsGet(
        organizationId,
        orderDirection,
        cursor,
        limit,
        networkIds,
        attributes,
        collectionIds,
        tokenIds,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get a public item by id
   * @param {string} itemId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public publicV1ItemsItemIdGet(itemId: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .publicV1ItemsItemIdGet(itemId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get item history
   * @param {string} itemId
   * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
   * @param {string} [cursor] SafeString
   * @param {number} [limit] PaginationLimit
   * @param {'MINT' | 'TRANSFER' | 'BURN' | 'null'} [eventType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public publicV1ItemsItemIdHistoryGet(
    itemId: string,
    orderDirection?: 'ASC' | 'DESC' | 'null',
    cursor?: string,
    limit?: number,
    eventType?: 'MINT' | 'TRANSFER' | 'BURN' | 'null',
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .publicV1ItemsItemIdHistoryGet(itemId, orderDirection, cursor, limit, eventType, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get item owners.
   * @param {string} itemId
   * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
   * @param {string} [cursor] SafeString
   * @param {number} [limit] PaginationLimit
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public publicV1ItemsItemIdOwnersGet(
    itemId: string,
    orderDirection?: 'ASC' | 'DESC' | 'null',
    cursor?: string,
    limit?: number,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .publicV1ItemsItemIdOwnersGet(itemId, orderDirection, cursor, limit, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get listings for the given organization
   * @param {string} organizationId
   * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
   * @param {string} [cursor] SafeString
   * @param {number} [limit] MediumPaginationLimit
   * @param {string} [itemId]
   * @param {string} [collectionId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public publicV1ListingsGet(
    organizationId: string,
    orderDirection?: 'ASC' | 'DESC' | 'null',
    cursor?: string,
    limit?: number,
    itemId?: string,
    collectionId?: string,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .publicV1ListingsGet(
        organizationId,
        orderDirection,
        cursor,
        limit,
        itemId,
        collectionId,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Listing for the given listing id
   * @param {string} listingId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public publicV1ListingsListingIdGet(listingId: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .publicV1ListingsListingIdGet(listingId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get metadata for a specific token in a collection
   * @param {string} collectionId
   * @param {string} tokenId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public publicV1MetadataCollectionIdTokenIdGet(
    collectionId: string,
    tokenId: string,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .publicV1MetadataCollectionIdTokenIdGet(collectionId, tokenId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get the pending mint vouchers for the ethereum address
   * @param {string} ethAddress
   * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
   * @param {string} [cursor] SafeString
   * @param {number} [limit] MediumPaginationLimit
   * @param {string} [contractAddress] ChecksumAddress
   * @param {number} [networkId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public publicV1MintVouchersEthAddressGet(
    ethAddress: string,
    orderDirection?: 'ASC' | 'DESC' | 'null',
    cursor?: string,
    limit?: number,
    contractAddress?: string,
    networkId?: number,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .publicV1MintVouchersEthAddressGet(
        ethAddress,
        orderDirection,
        cursor,
        limit,
        contractAddress,
        networkId,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get the available networks
   * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
   * @param {string} [cursor] SafeString
   * @param {number} [limit] PaginationLimit
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public publicV1NetworksGet(
    orderDirection?: 'ASC' | 'DESC' | 'null',
    cursor?: string,
    limit?: number,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .publicV1NetworksGet(orderDirection, cursor, limit, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   * The purchase intent can be made with any provider configured for the listing. The provider must be one of the following: STRIPE, COINBASE. The response data will have provider specific data in the data field.
   * @summary Create a purchase intent
   * @param {CreatePurchaseIntentInput} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public publicV1PurchaseIntentsPost(
    body?: CreatePurchaseIntentInput,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .publicV1PurchaseIntentsPost(body, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Request a cancel of a purchase intent for a given purchase intent id.
   * @param {string} purchaseIntentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public publicV1PurchaseIntentsPurchaseIntentIdDelete(
    purchaseIntentId: string,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .publicV1PurchaseIntentsPurchaseIntentIdDelete(purchaseIntentId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get payment tokens available for the given networks
   * @param {Array<number>} networkId
   * @param {'ASC' | 'DESC' | 'null'} [orderDirection]
   * @param {string} [cursor] SafeString
   * @param {number} [limit] PaginationLimit
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public publicV1TokensPaymentTokensGet(
    networkId: Array<number>,
    orderDirection?: 'ASC' | 'DESC' | 'null',
    cursor?: string,
    limit?: number,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .publicV1TokensPaymentTokensGet(networkId, orderDirection, cursor, limit, options)
      .then(request => request(this.axios, this.basePath))
  }
}
