import { Paper, Box, Typography, useTheme } from "@mui/material";
import { PublicItemOutput } from "../sdk-platform-public";
import { useListingContext } from "../utils/ListingContextProvider";
import { CustomCircularProgress } from "./common/CustomCircularProgress";
import { useMemo } from "react";

export const TokenDetails = ({
  item,
  itemLoadingFeedback,
  imageSize = "100%",
  preferredMediaSourceType = "cover",
}: {
  item?: PublicItemOutput;
  itemLoadingFeedback?: string;
  imageSize?: string;
  preferredMediaSourceType?: "cover" | "video";
}) => {
  const theme = useTheme();
  const { collection } = useListingContext();

  const preferredMediaSource = useMemo<string | undefined | null>(() => {
    if (
      preferredMediaSourceType === "video" &&
      (item?.attributes as any)?.meta_animation_url
    )
      return (item?.attributes as any)?.meta_animation_url;

    return (
      (item?.attributes as any)?.image_url ??
      collection?.media?.header_image_url ??
      collection?.media?.thumbnail_image_url ??
      ""
    );
  }, [
    preferredMediaSourceType,
    item?.attributes,
    collection?.media?.header_image_url,
    collection?.media?.thumbnail_image_url,
  ]);

  const mediaRequiresVideoElement = useMemo(() => {
    return (
      preferredMediaSource &&
      (preferredMediaSource.toLowerCase().endsWith(".gif") ||
        preferredMediaSource.toLowerCase().endsWith(".mp4") ||
        preferredMediaSource.toLowerCase().endsWith(".mov") ||
        preferredMediaSource.toLowerCase().endsWith(".m4v"))
    );
  }, [preferredMediaSource]);

  return (
    <Paper
      sx={{
        border: 0,
        backgroundColor: `${theme.palette.secondary.main} !important`,
        position: "relative",
        display: "grid",
        gridTemplateRows: `${imageSize === "100%" ? "1fr" : imageSize}`,
        rowGap: "6px",
        padding: "0",
        boxShadow: "0 0 3px 0 black !important",
        maxHeight: {
          lg: "82vh",
        },
        video: {
          lg: {
            maxHeight: "82vh",
          },
        },
        img: {
          lg: {
            maxHeight: "82vh",
          },
        },
      }}
    >
      {itemLoadingFeedback && (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            zIndex: 2,
            opacity: 0.9,
            display: "flex",
            position: "absolute",
            justifyContent: "center",
            alignItems: "center",
            rowGap: "24px",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{ color: `${theme.palette.primary.main} !important` }}
            fontWeight="bold"
            variant="h4"
          >
            {itemLoadingFeedback}
          </Typography>
          <CustomCircularProgress size={75} />
        </Box>
      )}

      {/* If a gif, then we can use a video element with poster attribute.
         This is way faster then rendering a gif on an img element.
         Especially if this component is used multiple times on the same page. */}
      {preferredMediaSource && mediaRequiresVideoElement ? (
        <video
          style={{
            height: "100%",
            width: "100%",
            objectFit: "contain",
          }}
          controls={false}
          poster={
            preferredMediaSource?.includes(".gif")
              ? preferredMediaSource
              : undefined
          }
          autoPlay
          muted // Most browsers wont play automatically if not muted
          loop
        >
          {(preferredMediaSource.toLowerCase().includes(".mp4") ||
            preferredMediaSource.toLowerCase().includes(".m4v")) && (
            <source src={preferredMediaSource} type="video/mp4" />
          )}
          {preferredMediaSource.toLowerCase().includes(".mov") && (
            <source src={preferredMediaSource} type="video/quicktime" />
          )}
          Your browser does not support the video tag.
        </video>
      ) : (
        <img
          style={{
            height: "100%",
            width: "100%",
            objectFit: "contain",
          }}
          src={
            (item?.attributes as any)?.image_url ??
            collection?.media?.header_image_url ??
            collection?.media?.thumbnail_image_url ??
            ""
          }
          alt="WABI SABI PASS"
        />
      )}
    </Paper>
  );
};

export default TokenDetails;
