import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Stack, Chip, useTheme, FormHelperText, Tooltip } from "@mui/material";

export interface ChipListProps<T> {
  /**
   * The elements to display.
   * By default, these have the primary color theme.
   */
  elements: Array<T>;

  /**
   * The function to use to format the label of each element.
   */
  labelFormatter: (element: T) => string | ReactJSXElement;

  /**
   * The minimum height of the list.
   */
  minHeight?: string;

  /**
   * The maximum height of the list.
   */
  maxHeight?: string;

  /**
   * The helper text to display. (if any)
   */
  helperText?: string;
}

/**
 * A component for visualizing list elements with Chips.
 */
export const ChipList = <T extends { [key: string]: any }>({
  elements,
  labelFormatter,
  minHeight = "40px",
  maxHeight = "150px",
  helperText,
}: ChipListProps<T>) => {
  const theme = useTheme();

  return (
    <Stack>
      <Stack
        sx={{
          border: 0,
          padding: "8px 0",
          borderRadius: "0",
          width: "100%",
          minHeight,
          maxHeight,
          overflowY: "auto",
          flexWrap: "wrap",
          rowGap: "12px",
          columnGap: "12px",
        }}
        direction="row"
      >
        {elements.map((element, index) => {
          const chipLabel = labelFormatter(element);
          return (
            <Tooltip key={index} title={chipLabel}>
              <Chip
                sx={{
                  border: `1px solid ${theme.palette.primary.main} !important`,
                  backgroundColor: `${theme.palette.primary.main} !important`,
                  color: "white !important",
                  fontFamily: "Anton",
                  textTransform: "uppercase",
                  padding: "8px",
                  fontSize: "1em",
                }}
                size="small"
                variant="filled"
                label={chipLabel}
              />
            </Tooltip>
          );
        })}
      </Stack>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </Stack>
  );
};
