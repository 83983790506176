import {
  Paper,
  Typography,
  Stack,
  Box,
  Skeleton,
  Link,
  LinearProgress,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import dayjs from "dayjs";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useListingContext } from "../utils/ListingContextProvider";
import { getTimeRemaining, getTokenPriceLabel } from "../utils/helpers";
import { BLOCK_EXPLORER_TX_HASH_URL } from "../config";
import { useWallet } from "../services/wallet";
import EthereumAddress from "./common/EthereumAddress";

const getTxHashBlockExplorerUrl = (txHash: string) => {
  if (!BLOCK_EXPLORER_TX_HASH_URL || !txHash) return undefined;

  return BLOCK_EXPLORER_TX_HASH_URL.endsWith("/")
    ? `${BLOCK_EXPLORER_TX_HASH_URL}${txHash}`
    : `${BLOCK_EXPLORER_TX_HASH_URL}/${txHash}`;
};

const ContentWrapper = ({
  title,
  children,
  stackedTitle,
}: PropsWithChildren & { title: string; stackedTitle: boolean }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: stackedTitle ? "flex-start" : "center",
        columnGap: "8px",
        rowGap: "8px",
        flexDirection: stackedTitle ? "column" : "row",
        justifyContent: stackedTitle ? "flex-start" : "space-between",
        flexGrow: 1,
      }}
    >
      <Typography
        textTransform="uppercase"
        letterSpacing="1px"
        fontFamily="Anton"
      >
        {title}
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          flexWrap: "wrap",
          rowGap: { xs: "8px", lg: "4px" },
          "> *:not(:last-child)::after": {
            content: '"|"',
            color: "primary.main",
            margin: "0 5px",
          },
          ".MuiTypography-root": {
            textTransform: "uppercase",
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

/**
 * Content to fill main component for when somebody wants to buy.
 */
const BuyContent = ({ stackedTitle }: { stackedTitle: boolean }) => {
  const { listing, listingLoading, listingSoldOut, collectionItems } =
    useListingContext();

  const [timeRemaining, setTimeRemaining] = useState<
    ReturnType<typeof getTimeRemaining> | undefined
  >();

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      if (!Boolean(listing?.end_time)) return;

      const wrappedEndTime = dayjs(listing?.end_time);
      const isActive = dayjs(wrappedEndTime).isAfter(dayjs());

      setTimeRemaining(() =>
        isActive ? getTimeRemaining(wrappedEndTime) : undefined
      );
    }, 1000);

    return () => clearTimeout(refreshInterval);
  }, [listing?.end_time]);

  const availableNowContentWrapperTitle = useMemo(() => {
    const labelCandidate = getTokenPriceLabel(
      listing?.price,
      listing?.currency
    );

    return labelCandidate
      ? `AVAILABLE NOW • ${labelCandidate}`
      : "AVAILABLE NOW";
  }, [listing?.price, listing?.currency]);

  return (
    <>
      {listingLoading && !listingSoldOut && (
        <Skeleton
          variant="rectangular"
          sx={{ width: "100%", height: "25px" }}
        ></Skeleton>
      )}

      {listingSoldOut && (
        <Typography
          textTransform="uppercase"
          letterSpacing="1px"
          fontFamily="Anton"
          sx={{ width: "100%" }}
        >
          SOLD OUT
        </Typography>
      )}

      {!listingLoading && !listingSoldOut && (
        <Box
          sx={{
            display: "flex",
            rowGap: "8px",
            columnGap: "8px",
            flexWrap: "wrap",
          }}
        >
          <ContentWrapper
            title={availableNowContentWrapperTitle}
            stackedTitle={stackedTitle}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              {/* This should be dynamic from the listing, but we don't have this data right now. */}
              <Typography textTransform="uppercase" sx={{ marginRight: "8px" }}>
                LIMIT 1 PER PERSON
              </Typography>

              {Boolean(listing?.quantity_listed) && (
                <Typography textTransform="uppercase">{`${collectionItems?.length} TOTAL SUPPLY`}</Typography>
              )}
            </Box>

            <Typography textTransform="uppercase">{`${listing?.quantity_remaining} REMAINING FOR SALE`}</Typography>
          </ContentWrapper>

          {timeRemaining !== undefined && timeRemaining !== null && (
            <Stack
              direction="row"
              sx={{
                columnGap: "3px",
                alignItems: "center",
              }}
            >
              <AccessTimeIcon fontSize="small" />
              <Typography>
                {`${timeRemaining.days}d ${timeRemaining.hours}h ${timeRemaining.minutes}m ${timeRemaining.seconds}s`}
              </Typography>
            </Stack>
          )}
        </Box>
      )}
    </>
  );
};

const BoughtContent = ({ stackedTitle }: { stackedTitle: boolean }) => {
  const { address } = useWallet();
  const { txItem, txItemId, txReceipt, txHashWalletAddress } =
    useListingContext();

  if (!txItem && txReceipt?.status !== "success") return <></>;

  if (!txItem) {
    return (
      <ContentWrapper title="YOU OWN" stackedTitle={stackedTitle}>
        <Box sx={{ display: "flex", columnGap: "8px" }}>
          <Typography>Getting Pass Details...</Typography>
          <LinearProgress />
        </Box>

        {/* Owned by a different wallet used on this browser to buy a pass */}
        {address &&
          txHashWalletAddress &&
          address.toLowerCase() !== txHashWalletAddress.toLowerCase() && (
            <Box sx={{ display: "flex", columnGap: "4px" }}>
              <Typography>Owned For</Typography>
              <EthereumAddress value={txHashWalletAddress} shortFormat />
            </Box>
          )}

        {txReceipt?.transactionHash && BLOCK_EXPLORER_TX_HASH_URL && (
          <Link
            href={getTxHashBlockExplorerUrl(txReceipt?.transactionHash)}
            target="_blank"
            rel="noopener noreferrer"
          >
            View TX record
          </Link>
        )}
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper title="YOU OWN" stackedTitle={stackedTitle}>
      <Box sx={{ display: "flex", columnGap: "16px" }}>
        {txItemId && (
          <Typography textTransform="uppercase">{`WABI SABI PASS ${txItemId}`}</Typography>
        )}

        {txItem?.token_id && (
          <Typography textTransform="uppercase">{`TOKEN ID ${txItem.token_id}`}</Typography>
        )}
      </Box>

      {/* Owned by a different wallet used on this browser to buy a pass */}
      {address &&
        txHashWalletAddress &&
        address.toLowerCase() !== txHashWalletAddress.toLowerCase() && (
          <Box sx={{ display: "flex", columnGap: "4px" }}>
            <Typography>Owned For</Typography>
            <EthereumAddress value={txHashWalletAddress} shortFormat />
          </Box>
        )}

      {txReceipt?.transactionHash && BLOCK_EXPLORER_TX_HASH_URL && (
        <Link
          href={getTxHashBlockExplorerUrl(txReceipt?.transactionHash)}
          target="_blank"
          rel="noopener noreferrer"
        >
          View TX record
        </Link>
      )}
    </ContentWrapper>
  );
};

const BuyingContent = ({ stackedTitle }: { stackedTitle: boolean }) => {
  const { address } = useWallet();
  const { txItem, txReceipt, txHash, txHashWalletAddress } =
    useListingContext();

  if (txItem || txReceipt || !txHash) return <></>;

  return (
    <ContentWrapper title="BUYING A PASS..." stackedTitle={stackedTitle}>
      {/* Owned by a different wallet used on this browser to buy a pass */}
      {address &&
        txHashWalletAddress &&
        address.toLowerCase() !== txHashWalletAddress.toLowerCase() && (
          <Box sx={{ display: "flex", columnGap: "4px" }}>
            <Typography>Owned For</Typography>
            <EthereumAddress value={txHashWalletAddress} shortFormat />
          </Box>
        )}

      {txHash && BLOCK_EXPLORER_TX_HASH_URL && (
        <Link
          href={getTxHashBlockExplorerUrl(txHash)}
          target="_blank"
          rel="noopener noreferrer"
        >
          View TX record
        </Link>
      )}
    </ContentWrapper>
  );
};

export const ListingPaper = ({
  stackedTitle,
  padding,
}: {
  stackedTitle: boolean;
  padding?: string;
}) => {
  const { txItem, txReceipt, txHash } = useListingContext();

  return (
    <Paper
      sx={{
        display: "flex",
        columnGap: "16px",
        justifyContent: "space-between",
        alignItems: "center",
        flexGrow: 1,
        backgroundColor: "transparent",
        padding: padding || "auto",
        ".MuiTypography-root": {
          fontWeight: "bold",
        },
      }}
    >
      {!txItem && txReceipt?.status !== "success" ? (
        txHash ? (
          <BuyingContent stackedTitle={stackedTitle} />
        ) : (
          <BuyContent stackedTitle={stackedTitle} />
        )
      ) : (
        <BoughtContent stackedTitle={stackedTitle} />
      )}
    </Paper>
  );
};
