import {
  Box,
  Stack,
  Typography,
  Chip,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material";
import BrawHausLogoAnimated from "../assets/wabi-sabi-pass-black.gif";
import { useListingContext } from "../utils/ListingContextProvider";
import { useEffect, useMemo, useState } from "react";
import { GetPublicOrgItemsPaginatedOutputResultsInner } from "../sdk-platform-public";
import { TokenDetails } from "../components/TokenDetails";
import { ListingPaper } from "../components/ListingPaper";
import { useNavigate } from "react-router-dom";
import PageLayout from "../components/page-layout";
import InfiniteScroll from "react-infinite-scroll-component";
import { uuidv4 } from "../utils/helpers";

const GalleryPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const { collectionItems, network, txReceipt } = useListingContext();
  const [itemFilterType, setItemFilterType] = useState<string>("ALL");
  const [activePageBoundary, setActivePageBoundary] = useState<number>(1);
  const [pageSize] = useState<number>(isMobileOrTablet ? 1 : 10);
  const [pageItems, setPageItems] = useState<
    GetPublicOrgItemsPaginatedOutputResultsInner[]
  >([]);

  const currentForSale = useMemo(() => {
    return collectionItems?.filter((item) => !item.token_id);
  }, [collectionItems]);

  const currentSold = useMemo(() => {
    return collectionItems?.filter((item) => item.token_id);
  }, [collectionItems]);

  const applicableItems = useMemo(() => {
    if (itemFilterType === "ALL") return collectionItems;
    else if (itemFilterType === "FOR SALE") return currentForSale;
    else if (itemFilterType === "BOUGHT") return currentSold;
    else return collectionItems;
  }, [collectionItems, currentForSale, currentSold, itemFilterType]);

  useEffect(() => {
    if (!applicableItems) setPageItems([]);
    setPageItems(
      applicableItems?.slice(0, activePageBoundary * pageSize) ?? []
    );
  }, [applicableItems, activePageBoundary, pageSize]);

  return (
    <PageLayout>
      <Box
        sx={{
          display: "grid",
          rowGap: { xs: "12px", lg: "40px" },
        }}
      >
        <Box
          sx={{
            display: { xs: "grid", lg: "flex" },
            columnGap: "80px",
            rowGap: "16px",
            img: {
              width: { xs: "175px", lg: "200px" },
              height: { xs: "175px", lg: "200px" },
              marginRight: { lg: "-25px" },
              marginLeft: { xs: "-25px" },
              marginTop: { lg: "-50px" },
            },
          }}
        >
          <img src={BrawHausLogoAnimated} alt="Braw Haus Logo" />

          {/* Title/subtitle */}
          <Stack sx={{ rowGap: "24px" }}>
            <Typography sx={{ columnGap: "5px", maxWidth: "900px" }}>
              The <strong>WABI SABI PASS</strong> is tailored for dedicated
              digital art collectors, providing a platform for holders to
              collectively showcase and collect artworks within a curated
              circle.
            </Typography>

            <Typography sx={{ columnGap: "5px", maxWidth: "900px" }}>
              Generative Dancer Series by{" "}
              <Typography
                component="a"
                target="_blank"
                href="https://www.kynd.info/"
                rel="noreferrer"
                sx={{
                  display: "inline",
                  marginLeft: "0px !important",
                  "&.MuiTypography-root:before": { display: "none" },
                }}
              >
                KYND
              </Typography>{" "}
              will be the first collection of the Wabi Sabi Pass. There are 135
              unique artworks connected to each pass, exclusively for the first
              135 Holders only.
            </Typography>

            <Box
              sx={{
                display: { xs: "grid", lg: "flex" },
                columnGap: "8px",
                rowGap: "16px",
                alignItems: "center",
              }}
            >
              {txReceipt?.status !== "success" && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ height: { xs: "50px", lg: "min-content" } }}
                  onClick={() => navigate("/buy")}
                >
                  Buy Now
                </Button>
              )}

              <ListingPaper
                padding="6px 12px"
                stackedTitle={isMobileOrTablet}
              />
            </Box>
          </Stack>
        </Box>

        <Box sx={{ display: "grid", rowGap: { xs: "12px", lg: "24px" } }}>
          {/* Bought/Minted metadata + filter */}
          <Stack
            sx={{
              flexDirection: { xs: "column", lg: "row" },
              justifyContent: { xs: "flex-start", lg: "space-between" },
              alignItems: { xs: "flex-start", lg: "flex-end" },
              rowGap: { xs: "16px", lg: "0px" },
            }}
          >
            <Stack
              direction="row"
              columnGap="24px"
              sx={{
                marginLeft: { lg: "25px" },
              }}
            >
              {network?.name && (
                <Typography fontWeight="bold">{`${network?.name}`}</Typography>
              )}
            </Stack>

            <ToggleButtonGroup
              color="primary"
              value={itemFilterType}
              exclusive
              onChange={(_, value) => {
                setActivePageBoundary(1);
                setItemFilterType(value);
              }}
              sx={{
                marginRight: { lg: "25px" },
              }}
            >
              <ToggleButton
                value="ALL"
                sx={{ display: "flex", columnGap: "8px" }}
              >
                All
                {collectionItems && (
                  <Chip size="small" label={collectionItems?.length} />
                )}
              </ToggleButton>

              <ToggleButton
                value="FOR SALE"
                sx={{ display: "flex", columnGap: "8px" }}
              >
                Remaining For Sale
                {currentForSale && (
                  <Chip size="small" label={currentForSale?.length} />
                )}
              </ToggleButton>

              <ToggleButton
                value="BOUGHT"
                sx={{ display: "flex", columnGap: "8px" }}
              >
                Minted
                {currentSold && (
                  <Chip size="small" label={currentSold?.length} />
                )}
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>

          <Box
            sx={{
              ".infinite-scroll-component": {
                display: "grid",
                gridTemplateColumns: {
                  xs: "1fr",
                  lg: `repeat(${5}, 1fr)`, // Assume we can render 5 columns on a lg screen size
                },
                columnGap: "16px",
                video: {
                  minHeight: "400px",
                },
              },
            }}
          >
            <InfiniteScroll
              dataLength={pageItems?.length ?? 0} //This is important field to render the next data
              next={() => {
                setActivePageBoundary(activePageBoundary + 1);
              }}
              hasMore={
                Boolean(applicableItems?.length) &&
                Boolean(pageItems?.length) &&
                (applicableItems as any).length > (pageItems as any).length
              }
              loader={<h4>Loading...</h4>}
            >
              {pageItems
                ?.sort((item) => (!item.token_id ? -1 : Number(item.token_id)))
                ?.map((item) => (
                  <Box
                    key={uuidv4()}
                    sx={{
                      position: "relative",
                      mb: "16px",
                      maxWidth: { xs: "inherit", lg: "400px" },
                    }}
                  >
                    <Chip
                      color={item.token_id ? "success" : "info"}
                      label={item.token_id ? "Minted" : "For Sale"}
                      sx={{
                        position: "absolute",
                        bottom: "30px",
                        right: "10px",
                        zIndex: 1,
                        p: 0,
                        height: "18px",
                        "MuiChip-label": {
                          p: "0 6px",
                        },
                        "&.MuiChip-filledSuccess": {
                          backgroundColor: "white !important",
                          color: "black !important",
                        },
                      }}
                    />
                    <TokenDetails
                      item={item}
                      preferredMediaSourceType="cover"
                    />
                  </Box>
                ))}
            </InfiniteScroll>
          </Box>
        </Box>
      </Box>
    </PageLayout>
  );
};

export default GalleryPage;
