import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";

/**
 * Based on https://mui.com/material-ui/react-progress/#customization
 * Used to have a custom circular progress matching the theme, with basically two instances that have different colors and animations.
 */
export const CustomCircularProgress = ({ size }: { size: number }) => {
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.primary.main,
        }}
        value={100}
        thickness={4}
        size={size}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) => theme.palette.primary.light,
          animationDuration: "1750ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={size}
        thickness={4}
      />
    </Box>
  );
};
