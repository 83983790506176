import { useEffect, useMemo, useState } from "react";
import LayersIcon from "@mui/icons-material/Layers";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useWallet } from "../services/wallet";
import { MINT_VOUCHER_ABI } from "../utils/constants";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import BrawHausLogoAnimated from "../assets/wabi-sabi-pass-black.gif";
import { extractTraits, getTokenPriceLabel, uuidv4 } from "../utils/helpers";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  Tooltip,
  Link,
} from "@mui/material";
import { useListingContext } from "../utils/ListingContextProvider";
import PageLayout from "../components/page-layout";
import { BigNumber } from "ethers";
import { usePrepareContractWrite, useContractWrite } from "wagmi";
import { CreatePurchaseIntentOutput } from "../sdk-platform-public";
import { VoucherOutput } from "../types";
import { useCreatePurchaseIntent } from "../utils/hooks";
import TokenDetails from "../components/TokenDetails";
import { ListingPaper } from "../components/ListingPaper";
import { ChipList } from "../components/common/ChipList";
import { LoadingButton } from "../components/common/LoadingButton";
import { useNavigate } from "react-router-dom";
import { ConnectButton } from "@rainbow-me/rainbowkit";

export const BuyPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const {
    isRightNetwork,
    rightNetwork,
    switchNetwork,
    address,
    isConnected,
    connectors,
    disconnect,
  } = useWallet();
  const [buyProcessError, setBuyProcessError] = useState<string>();
  const {
    listing,
    listingLoading,
    network,
    updateBuyMetadata,
    txHash,
    listingSoldOut,
    txItem,
    txReceipt,
  } = useListingContext();
  const [contractWriteCacheKey, setContractWriteCacheKey] = useState<
    string | undefined
  >(undefined);
  const { mutate: createPurchaseIntent, isLoading: creatingPurchaseIntent } =
    useCreatePurchaseIntent();
  const [voucherOutput, setVoucherOutput] = useState<VoucherOutput | undefined>(
    undefined
  );

  // Calculate the purchase price of the voucher,
  // Which is needed to pass along 'payable modifier in solidity' to the contract call.
  const mintCost =
    Boolean(voucherOutput?.voucher?.price) &&
    Boolean(voucherOutput?.voucher?.quantity)
      ? BigNumber.from(voucherOutput?.voucher?.price).mul(
          voucherOutput?.voucher?.quantity
        )
      : undefined;

  // Use wagmi to prepare a config that we can use to do a smart contract write
  // This will be done based on the voucher that was reserved for the user.
  // Internally this uses viem to simulate the contract call, and then it will know how much gas is needed.
  const { config } = usePrepareContractWrite({
    enabled:
      Boolean(voucherOutput?.contract) &&
      Boolean(network?.id) &&
      Boolean(voucherOutput?.voucher) &&
      Boolean(voucherOutput?.signature) &&
      Boolean(mintCost) &&
      !voucherOutput?.submitting &&
      txReceipt?.status !== "success" &&
      MINT_VOUCHER_ABI &&
      MINT_VOUCHER_ABI.length &&
      MINT_VOUCHER_ABI[0].name,
    address: voucherOutput?.contract as any,
    abi: MINT_VOUCHER_ABI,
    functionName: MINT_VOUCHER_ABI[0].name,
    chainId: network?.id,
    args: [
      {
        netRecipient: voucherOutput?.voucher?.net_recipient,
        initialRecipient: voucherOutput?.voucher?.initial_recipient,
        initialRecipientAmount:
          voucherOutput?.voucher?.initial_recipient_amount,
        quantity: voucherOutput?.voucher?.quantity,
        nonce: voucherOutput?.voucher?.nonce,
        expiry: voucherOutput?.voucher?.expiry,
        price: voucherOutput?.voucher?.price,
        tokenId: voucherOutput?.voucher?.token_id,
        currency: voucherOutput?.voucher?.currency,
        // tokenURI: voucherOutput?.voucher?.token_uri, N/A for Mint Voucher V1
      },
      voucherOutput?.signature,
    ],
    value: mintCost as any,
    scopeKey: contractWriteCacheKey,
    onError: (e) => {
      // If there's an error here, but we have a voucher, and we didn't have a successfull buy yet..
      if (voucherOutput && txReceipt?.status !== "success") {
        // Reset voucher so the user has to go through the entire process again.
        setVoucherOutput(undefined);

        // Determine if the user must wait before buying again. (more info - see next comment)
        const buyerMustWait = e?.message && e.message.includes("0x9da17a68"); // VoucherNonceTooLow

        // Slight delay for the core platform to know when a previous voucher has been validated on chain
        // Until it knows, it will keep sending a voucher with the 'old' nonce, which will cause this error.
        // This means a buyer can't really buy until the core platform is up to date with the on-chain state.
        if (!buyerMustWait) {
          setBuyProcessError(
            "An issue occured while preparing a transaction." +
              (e && "shortMessage" in e ? (e as any)["shortMessage"] || "" : "")
          );
          console.error(e);
        } else {
          // Some error while simulating the contract write call config.
          // e.g. ABI incorrect for the mint voucher params?
          setBuyProcessError(
            "An issue occured while preparing a transaction. You have recently purchased a pass and must wait a short amount of time before allowed to buy again. Please try again shortly."
          );
        }
      }
    },
  });

  // Use wagmi hook to give us a smart contract write function based on the prepared config.
  const {
    writeAsync,
    status: txWriteStatus,
    reset: resetTxWriteStatus,
  } = useContractWrite(config);
  const approvingOrSubmittingTx = txWriteStatus === "loading";

  // Clear the voucher if anything goes wrong and we have set an error ourselves.
  // This will cause a reset of the entire process (purchase intent -> prep contract write -> contract write, ...)
  useEffect(() => {
    if (Boolean(buyProcessError) && Boolean(voucherOutput?.submitting)) {
      setVoucherOutput(undefined);
    }
  }, [voucherOutput, buyProcessError]);

  // Every time a voucher is (re)loaded, make sure we reset the contract write cache key
  // This is to ensure that the contract write hook does NOT use any cached data.
  // Which could cause weird glitches when trying to write. (e.g. unknown contract data error)
  useEffect(() => {
    setContractWriteCacheKey(uuidv4());
    if (!voucherOutput) {
      resetTxWriteStatus();
    }
  }, [resetTxWriteStatus, updateBuyMetadata, voucherOutput]);

  useEffect(() => {
    // If:
    // - the contract call simulation (config) isn't ready
    // - the contract call async fn isn't ready
    // - We already have a tx hash that we're processing
    // - We already have a voucher that we're processing
    // - We already have a tx receipt that is completely valid
    // Then shortcircuit and DONT issue another contract write.
    if (
      !config ||
      config.mode !== "prepared" ||
      txWriteStatus !== "idle" ||
      !writeAsync ||
      Boolean(txHash) ||
      !voucherOutput ||
      voucherOutput?.submitting ||
      txReceipt?.status === "success"
    )
      return;

    // Can't use async hook functions, so we have to define the async function inline.
    async function mintVoucherInContract() {
      if (writeAsync && voucherOutput && !voucherOutput.submitting) {
        try {
          // Reset any error in the flow that was previously enounctered.
          setBuyProcessError(undefined);

          // Mark this voucher as submitting.
          // This is needed because the functional nature of react would otherwise cause one
          //  of the dependencies of this hook to reload and do a second contract write. (!!)
          // Even though no dependencies were changed. (Probably the config instance that changed, even though no values for it changed)
          voucherOutput.submitting = true;

          // Write to the smart contract through one of our provider connectors.
          const tx = await writeAsync();

          // Update the buy metadata with the tx hash that is waiting to be validated.
          updateBuyMetadata(tx.hash, undefined);
        } catch (e: any) {
          // Something went wrong while submitting the transaction.
          console.error(e);

          // No tx hash and no tx receipt should be in our state.
          updateBuyMetadata(undefined, undefined);

          // Show appropriate error message to the user.
          setBuyProcessError(
            "Failed to initiate transaction. " +
              (e && "shortMessage" in e ? (e as any)["shortMessage"] || "" : "")
          );
        }
      }
    }
    mintVoucherInContract();
  }, [
    writeAsync,
    txWriteStatus,
    txHash,
    updateBuyMetadata,
    voucherOutput,
    config,
    resetTxWriteStatus,
    txReceipt?.status,
  ]);

  // Create a purchase intent for a (new/existing) mint voucher.
  const handleBuy = async () => {
    if (!address || !listing?.id) return;
    createPurchaseIntent(
      {
        dto: {
          provider: "MINT_VOUCHER",
          buyer: { eth_address: address },
          listing_id: listing?.id,
          quantity: 1,
        },
      },
      {
        onSettled: async (result: {
          data: { data: CreatePurchaseIntentOutput };
        }) => {
          // Keep track of the voucher that we got back from the API
          // Which will then be used to prepare a contract write,
          //  and then immmediately (no user action required) ask for a tx approval.
          setVoucherOutput(result?.data?.data as any);
        },

        onError: (e: any) => {
          // Hit buy limit for this address
          if (
            e?.response?.data?.error?.detail &&
            (e?.response?.data?.error?.detail as string)
              ?.toLowerCase()
              ?.includes("exceeds limit") &&
            e?.response?.data?.error?.status === "422"
          ) {
            // Show error to user that they have reached the buy limit.
            setBuyProcessError(
              "You have reached the purchase limit for the WABI SABI PASS sale."
            );
          }
        },
      }
    );
  };

  // Helpers
  // - tx is validated when tx hash is known but no tx receipt is known yet.
  // - Bought pass is retrieved when tx receipt is known but no tx item is known yet. (waiting for core platform to catch up with on-chain state)
  const txBeingValidated = Boolean(txHash) && !txReceipt;
  const retrievingBoughtPass = txReceipt?.status === "success" && !txItem;

  // Main Button - loading & it's waiting text.
  const isButtonLoading = useMemo(() => {
    return (
      creatingPurchaseIntent || approvingOrSubmittingTx || txBeingValidated
    );
  }, [creatingPurchaseIntent, approvingOrSubmittingTx, txBeingValidated]);
  const buttonLoadingText = useMemo(() => {
    if (creatingPurchaseIntent) return "Reserving Pass";
    else if (approvingOrSubmittingTx) return "Approving Transaction";
    else if (txBeingValidated) return "Transaction Processing";
    else return "";
  }, [creatingPurchaseIntent, approvingOrSubmittingTx, txBeingValidated]);

  // Main Button - text for when it is not loading.
  const buttonNotLoadingText = useMemo(() => {
    if (retrievingBoughtPass) return "Transaction Successful";
    if (listingSoldOut) return "SOLD OUT";

    return listing?.price && listing?.currency ? (
      <>{`Buy for ${getTokenPriceLabel(listing?.price, listing?.currency)}`}</>
    ) : (
      "PRICE UNAVAILABLE"
    );
  }, [listing?.currency, listing?.price, listingSoldOut, retrievingBoughtPass]);

  // Traits can only be shown when we have a validated tx and the core platform knows which item was bought.
  const canShowTraits = useMemo(() => {
    return (
      txReceipt?.status === "success" &&
      txItem?.attributes &&
      Object.keys(txItem.attributes).length > 0
    );
  }, [txReceipt?.status, txItem?.attributes]);
  const txItemTraits = useMemo(() => {
    return canShowTraits && txItem ? extractTraits(txItem) : [];
  }, [canShowTraits, txItem]);

  // Update our listing context with the tx hash and tx receipt if necessary.
  useEffect(() => {
    updateBuyMetadata(txHash, txReceipt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [txHash, txReceipt]);

  const rowGap = isMobileOrTablet ? "24px" : "12px";

  // If the tx receipt is reverted, show an error to the user.
  // IF the tx receipt is successful (tx was validated), clear any error and mark voucher as not submitting anymore.
  useEffect(() => {
    if (txReceipt?.status === "reverted") {
      setBuyProcessError(
        "Failed to buy a WABI SABI PASS. This could be because the network is busy or the transaction was cancelled. Please Try Again."
      );
    } else if (txReceipt?.status === "success") {
      if (voucherOutput && voucherOutput.submitting)
        voucherOutput.submitting = false;
      setBuyProcessError(undefined);
    }
  }, [txReceipt?.status, voucherOutput]);

  // Right side grid column (large layout) is dynamic based on the content that is shown.
  const rightSideGridRows = useMemo(() => {
    let rows = 5;

    if (canShowTraits) rows += 1;
    if (buyProcessError) rows += 1;

    return rows;
  }, [canShowTraits, buyProcessError]);
  const rightSideWidth = isMobileOrTablet ? "100%" : "425px";

  return (
    <PageLayout>
      <Box
        sx={{
          display: { xs: "flex", lg: "grid" },
          flexDirection: { xs: "column" },
          gridTemplateColumns:
            listing || txItem
              ? `max-content max-content ${rightSideWidth}`
              : `max-content ${rightSideWidth}`,
          gridTemplateRows: "min-content 1fr",
          columnGap: "75px",
          rowGap: rowGap,
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        {/* Left Column (large scren) */}
        <Link
          target="_blank"
          rel="noopener noreferrer"
          display="flex"
          alignItems="center"
          sx={{
            width: { xs: "175px", lg: "200px" },
            height: { xs: "175px", lg: "200px" },
            marginRight: { lg: "-25px" },
            marginLeft: { xs: "-25px" },
            img: {
              marginTop: { lg: "-100px" },
            },
          }}
        >
          <img
            src={BrawHausLogoAnimated}
            style={{
              width: "100%",
              height: "100%",
            }}
            alt="Braw Haus Logo"
          />
        </Link>

        {/* Middle column (large screen) */}
        {(listing || txItem) && (
          <Box
            sx={{
              display: "grid",
              rowGap: "8px",
            }}
          >
            <TokenDetails
              item={txItem}
              itemLoadingFeedback={
                txReceipt?.status === "success" && !txItem
                  ? `Loading Your Pass`
                  : undefined
              }
              preferredMediaSourceType="video"
            />
          </Box>
        )}

        {/* Right column (large screen) */}
        <Box
          sx={{
            display: "grid",
            rowGap: rowGap,
            gridTemplateRows: `repeat(${rightSideGridRows}, max-content`,
          }}
        >
          {/* Buttons & listing summary paper */}
          <Box
            sx={{
              display: "grid",
              rowGap: rowGap,
              ".MuiButton-root": {
                height: "60px",
                fontSize: "24px",
              },
            }}
          >
            {/* If we haven't successfully bought yet, or we're still getting the bought token info.. */}
            {(txReceipt?.status !== "success" || retrievingBoughtPass) && (
              <>
                {/* 
                   If we're connected to the right network:
                   - show the main (buy/tx) button 
                   - show the checkout details (if necessary)
                   - show any error associated with the (buy/tx) process

                   If we're not connected to the right network:
                   - show a switch network button
                   - show the checkout details
                   - show which network they need to be connected with
                */}
                {isConnected ? (
                  <>
                    <Paper
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        rowGap: "16px",
                        ".MuiTypography-root": {
                          fontWeight: "bold",
                        },
                      }}
                    >
                      {isRightNetwork ? (
                        <LoadingButton
                          sx={{
                            width: "100%",
                            cursor: retrievingBoughtPass
                              ? "not-allowed"
                              : "inherit",
                          }}
                          variant="contained"
                          loadingText={buttonLoadingText}
                          loading={isButtonLoading}
                          disabled={
                            listingLoading || listingSoldOut || isButtonLoading
                          }
                          onClick={() => {
                            if (!retrievingBoughtPass) handleBuy();
                          }}
                        >
                          {buttonNotLoadingText}
                        </LoadingButton>
                      ) : (
                        <Button
                          variant="contained"
                          sx={{ width: "100%" }}
                          onClick={() => {
                            if (switchNetwork && listing?.network_id) {
                              switchNetwork(listing.network_id);
                            }
                          }}
                        >
                          Switch Network To Buy
                        </Button>
                      )}

                      <Box sx={{ display: "flex", columnGap: "4px" }}>
                        <Typography>+ Transfer Fees</Typography>

                        <Tooltip
                          title={
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                rowGap: "16px",
                              }}
                            >
                              <Typography>
                                Much like a shipping or tax charge, there are
                                fees to transfer this collectible to your
                                preferred wallet.
                              </Typography>

                              <Typography>
                                These fees go to the network to cover the gas
                                costs to record your ownership on a blockchain,
                                and may vary depending on the network and
                                network activity.
                              </Typography>
                            </Box>
                          }
                        >
                          <HelpOutlineIcon
                            fontSize="small"
                            sx={{ color: theme.palette.primary.main }}
                          />
                        </Tooltip>
                      </Box>

                      {address && address.length >= 4 && (
                        <Paper sx={{ display: "flex", columnGap: "8px" }}>
                          <Typography
                            fontSize="14px"
                            sx={{ wordSpacing: "10px" }}
                          >
                            0x
                          </Typography>

                          <Typography fontSize="14px">
                            {address
                              .substring(0, address.length - 5)
                              .replaceAll(/[A-z0-9]/g, ".")
                              .match(/.{1,4}/g)
                              ?.join(" ")}
                          </Typography>

                          <Typography fontSize="14px">
                            {address.substring(
                              address.length - 5,
                              address.length
                            )}
                          </Typography>
                        </Paper>
                      )}

                      <Button
                        color="primary"
                        variant="text"
                        sx={{ fontSize: "14px !important" }}
                        onClick={() => disconnect()}
                      >
                        Disconnect Wallet
                      </Button>

                      {isRightNetwork && buyProcessError && (
                        <Alert severity="error">{buyProcessError}</Alert>
                      )}

                      {!isRightNetwork && (
                        <Alert
                          icon={false}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            border: "1px solid rgba(110, 113, 145, 1)",
                            backgroundColor: "rgba(110, 113, 145, 1)",
                            color: "white",
                          }}
                        >{`Must be connected to network ${
                          rightNetwork?.name
                            ? rightNetwork.name
                            : "a different network"
                        } to buy.`}</Alert>
                      )}
                    </Paper>
                  </>
                ) : (
                  <ConnectButton.Custom>
                    {({ openConnectModal }) => (
                      <Button
                        variant="contained"
                        disabled={!listing?.network_id}
                        onClick={() => {
                          try {
                            if (!isConnected && connectors?.length) {
                              openConnectModal();
                            }
                          } catch (error) {
                            console.error(error);
                          }
                        }}
                      >
                        Connect Wallet To Buy
                      </Button>
                    )}
                  </ConnectButton.Custom>
                )}
              </>
            )}

            {/* If we've successfully bought and know which item through the core platform,
                we show some referral links as buttons. */}
            {txReceipt?.status === "success" && txItem && (
              <>
                {isMobileOrTablet ? (
                  <Button
                    variant="contained"
                    href="https://metamask.zendesk.com/hc/en-us/articles/360058238591-NFT-tokens-in-your-MetaMask-wallet"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Import in MetaMask Mobile
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    href="https://portfolio.metamask.io/?tab=nfts"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View in MetaMask Portfolio
                  </Button>
                )}

                <Button variant="outlined" onClick={() => navigate("/gallery")}>
                  View WABI SABI Gallery
                </Button>
              </>
            )}

            {/* If
              - we're buying a token
              - we've bought a token
              - we can't show the proper buy info yet (no network) */}
            {(txHash || txReceipt?.status === "success" || !isConnected) && (
              <ListingPaper stackedTitle />
            )}
          </Box>

          {/* Start Listing Info */}
          <Typography fontSize="14px">
            The <strong>Wabi Sabi Pass</strong> is for dedicated collectors and
            enthusiasts who are dedicated to supporting both emerging and
            established digital artists.
          </Typography>

          <Typography fontSize="14px">
            Pass holders will have <strong>early access to artworks</strong>{" "}
            from established and emerging artists, and{" "}
            <strong>co-curate with Braw Haus</strong> exhibitions and digital
            showcases.
          </Typography>

          <Typography fontSize="14px">
            The Pass grants access to <strong>a niche community</strong> where
            we all contribute to share and discover talent in the{" "}
            <strong>ever-evolving realm of digital artistry</strong> across the
            world.
          </Typography>

          <Box sx={{ display: "grid", rowGap: "8px" }}>
            <Typography fontSize="14px" fontWeight="bold">
              With each pass:
            </Typography>

            <Box
              sx={{
                display: "grid",
                rowGap: "8px",
                ".MuiTypography-root": { ml: 1 },
                ".MuiTypography-root:before": {
                  content: '"•"',
                  mr: 1,
                  color: theme.palette.primary.main,
                },
              }}
            >
              <Typography fontSize="14px">
                Become an official digital collector, with access to a very
                first small curators group
              </Typography>

              <Typography fontSize="14px">
                Receive an exclusive airdrop of an original{" "}
                <Typography
                  component="a"
                  target="_blank"
                  href="https://www.kynd.info/"
                  rel="noreferrer"
                  sx={{
                    display: "inline",
                    marginLeft: "0px !important",
                    "&.MuiTypography-root:before": { display: "none" },
                  }}
                >
                  KYND
                </Typography>{" "}
                digital artwork from your pass.
              </Typography>

              <Typography fontSize="14px">
                Connect with artists, opportunity to build their careers.
              </Typography>

              <Typography fontSize="14px">
                Participate in co-curating drops, showcases, and attend private
                events.
              </Typography>

              <Typography fontSize="14px">
                50% of sales from the first pass will go to a community fund for
                the pass holders.
              </Typography>
            </Box>
          </Box>

          <Typography fontSize="14px">
            135{" "}
            <Typography sx={{ display: "inline", fontStyle: "italic" }}>
              Unique generated dancers with Openframeworks
            </Typography>
          </Typography>

          <Typography fontSize="14px" sx={{ mt: "-10px" }}>
            1080 x 1080 PX (30 FPS)
          </Typography>

          <Box display="flex" sx={{ columnGap: "16px", mt: "-10px" }}>
            <Typography
              component="a"
              target="_blank"
              href="https://www.instagram.com/kyndinfo/"
              rel="noreferrer"
              sx={{ fontWeight: "bold" }}
            >
              @Kyndinfo
            </Typography>
            <Typography
              component="a"
              target="_blank"
              href="https://www.kynd.info/"
              rel="noreferrer"
              sx={{ fontWeight: "bold" }}
            >
              Kynd website
            </Typography>
          </Box>

          {/* Optionally, traits - when bought and metadata is available through platform */}
          {canShowTraits && (
            <Accordion sx={{ maxWidth: isMobileOrTablet ? "350px" : "auto" }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box sx={{ display: "flex", columnGap: "8px" }}>
                  <LayersIcon />
                  <Typography>Metadata</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ maxWidth: rightSideWidth }}>
                <ChipList
                  elements={txItemTraits}
                  labelFormatter={(element) =>
                    `${element.name}: ${element.value}`
                  }
                />
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
      </Box>
    </PageLayout>
  );
};
