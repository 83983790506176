export const INFURA_KEY = window.env.REACT_APP_INFURA_KEY;
export const CORE_API_PUBLIC_BASE_URL =
  window.env.REACT_APP_CORE_API_PUBLIC_BASE_URL;
export const ORGANIZATION_ID = window.env.REACT_APP_ORGANIZATION_ID;
export const LISTING_ID = window.env.REACT_APP_LISTING_ID;
export const COLLECTION_ID = window.env.REACT_APP_COLLECTION_ID;
export const NETWORK_ID = window.env.REACT_APP_NETWORK_ID;
export const ID_ATTRIBUTE = window.env.REACT_APP_ID_ATTRIBUTE;
export const WALLET_CONNECT_PROJECT_ID =
  window.env.REACT_APP_WALLET_CONNECT_PROJECT_ID;
export const BLOCK_EXPLORER_TX_HASH_URL =
  window.env.REACT_APP_BLOCK_EXPLORER_TX_HASH_URL;
