import { Box, Container, useMediaQuery } from "@mui/material";
import theme from "../../theme";
import Footer from "./Footer";
import { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";

interface PageLayoutProps extends PropsWithChildren {}

const PageLayout = ({ children }: PageLayoutProps) => {
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container
      maxWidth={false}
      sx={{
        height: "100vh",
        width: "100vw",
        mx: isMobileOrTablet ? "auto" : "0 auto",
        display: "grid",
        gridTemplateRows: "1fr auto",
        padding: isMobileOrTablet
          ? "7.5% 7.5% 7.5% 7.5%"
          : "2.5% 7.5% 2.5% 7.5%",
        paddingLeft: "7.5% !important",
        paddingRight: "7.5% !important",
        rowGap: "24px",
      }}
    >
      <Box>{children ?? <Outlet />}</Box>

      <Footer />
    </Container>
  );
};

export default PageLayout;
