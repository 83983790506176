import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useInfiniteQuery } from "@tanstack/react-query";
import { AxiosPromise } from "axios";
import { useEffect, useMemo } from "react";
import {
  DefaultApiFactory as PublicApiFactory,
  Configuration as PlatformPublicConfiguration,
  GetPublicOrgItemsPaginatedOutputResultsInner,
  PublicNetworksPaginatedOutputResultsInner,
  PublicNetworksPaginatedOutput,
  CreatePurchaseIntentInput,
  PublicListingOutput,
} from "../sdk-platform-public";
import axios from "axios";
import {
  CORE_API_PUBLIC_BASE_URL,
  LISTING_ID,
  ORGANIZATION_ID,
} from "../config";

const publicCore = PublicApiFactory(
  new PlatformPublicConfiguration({ basePath: CORE_API_PUBLIC_BASE_URL }),
  undefined,
  axios.create({
    baseURL: CORE_API_PUBLIC_BASE_URL,
    headers: {
      "Content-Type": "application/json",
    },
  })
);

export const useApi = () => {
  return {
    publicCore,
  };
};

export interface UseAllPagesOutput<TOutput> {
  isLoading: boolean;
  isFetched: boolean;
  isError: boolean;
  data: TOutput[];
}

export interface PaginatedOutput<T> {
  cursor?: string | null;
  has_more: boolean;
  results: Array<T>;
  total_results: number;
}

export interface OutputResultsResponse<TOutput> {
  results?: TOutput[];
  has_more?: boolean;
  cursor?: string | null;
  total_results?: number;
}

/**
 * Helper to recursively get all pages from a paginated endpoint.
 */
export function useAllPages<
  TOutput,
  TOutputResponse extends OutputResultsResponse<TOutput>
>(
  queryKey: string | string[],
  cursorGetFn: (cursor: string | undefined) => AxiosPromise<TOutputResponse>,
  queryOptions?: object
): UseAllPagesOutput<TOutput> {
  const fetchForCursor = async ({ pageParam = undefined }) => {
    return cursorGetFn(pageParam).then((res) => res.data);
  };
  const coercedoptions: any = queryOptions ?? {};
  const {
    data: pagesData,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isError,
  } = useInfiniteQuery(
    typeof queryKey === "string" ? [queryKey] : queryKey,
    fetchForCursor,
    {
      getNextPageParam: (lastPage: TOutputResponse) => {
        const castedLastPage = lastPage;
        return castedLastPage.has_more &&
          castedLastPage.cursor &&
          Boolean(castedLastPage.total_results)
          ? castedLastPage.cursor
          : undefined;
      },
      ...coercedoptions,
    }
  );

  // Ongoing issue which is returning `isLoading=True` when query is disabled.
  // https://github.com/TanStack/query/issues/3584
  const actualIsLoading =
    isLoading &&
    (!coercedoptions ||
      coercedoptions["enabled"] === undefined ||
      coercedoptions["enabled"] === true);

  useEffect(() => {
    if (hasNextPage && pagesData) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage, pagesData]); // Must include data; otherwise it won't fetch recursively for some reason.

  const data: Array<TOutput> = useMemo(() => {
    return (
      pagesData?.pages.flatMap((page: TOutputResponse) => page.results ?? []) ??
      []
    );
  }, [pagesData]);

  return {
    isLoading: actualIsLoading,
    isFetched: hasNextPage === false && Boolean(pagesData?.pages),
    isError,
    data: data ?? [],
  };
}

export const useListing = () => {
  const { publicCore } = useApi();
  return useQuery<PublicListingOutput>(
    ["LISTING", LISTING_ID],
    () =>
      publicCore
        .publicV1ListingsListingIdGet(LISTING_ID ?? "")
        .then((res: any) => res?.data),
    {
      enabled: !!LISTING_ID,
    }
  );
};

export const useAllPublicNetworks = () => {
  const { publicCore } = useApi();
  return useAllPages<
    PublicNetworksPaginatedOutputResultsInner,
    PublicNetworksPaginatedOutput
  >(
    ["NETWORK", "public", "all"],
    (cursor: string | undefined) =>
      publicCore.publicV1NetworksGet("ASC", cursor, 100),
    {
      // this should rarely change
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
};

export const useCollection = (collectionId?: string | null) => {
  const { publicCore } = useApi();
  return useQuery(
    ["COLLECTION", collectionId ?? ""],
    () =>
      publicCore
        .publicV1CollectionsCollectionIdGet(collectionId as string)
        .then((res: any) => res?.data),
    {
      enabled: Boolean(collectionId),
    }
  );
};

export const useCollectionItems = (
  networkId?: number | null,
  collectionId?: string | null,
  options?: any
) => {
  const { publicCore } = useApi();

  return useAllPages<
    GetPublicOrgItemsPaginatedOutputResultsInner,
    PaginatedOutput<GetPublicOrgItemsPaginatedOutputResultsInner>
  >(
    [
      "ORGANIZATION",
      ORGANIZATION_ID as any,
      "NETWORK",
      networkId as any,
      "COLLECTION",
      collectionId ?? "",
      "ITEM",
    ],
    (cursor: string | undefined) =>
      publicCore.publicV1ItemsGet(
        ORGANIZATION_ID ?? "",
        "ASC",
        cursor,
        1000,
        [networkId as number],
        undefined,
        [collectionId as string]
      ) as any,
    {
      enabled:
        Boolean(ORGANIZATION_ID) && Boolean(networkId) && Boolean(collectionId),
      ...(options ?? {}),
    }
  );
};

export const useCreatePurchaseIntent = () => {
  const { publicCore } = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    ({ dto }: { dto: CreatePurchaseIntentInput }) =>
      publicCore.publicV1PurchaseIntentsPost(dto) as any,
    {
      cacheTime: 0,
      onError: () => {
        // Might be sold out
        queryClient.invalidateQueries(["LISTING"]);
      },
    }
  ) as any;
};
