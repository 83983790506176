import Button, { ButtonProps } from "@mui/material/Button";
import { useCallback, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { ColoredBorderLoader } from "../common/ColoredBorderLoader";

interface LoadingButtonProps {
  loading?: boolean;
  loadingText?: string;
  delay?: number;
  minDuration?: number;
}

export const LoadingButton = ({
  loading,
  loadingText,
  children,
  delay,
  minDuration,
  ...rest
}: ButtonProps & LoadingButtonProps) => {
  const timeoutInstance = useRef<any>();
  const setTimeoutInstance = useCallback((timeout: any) => {
    timeoutInstance.current = timeout;
  }, []);
  const [canShowLoader, setCanShowLoader] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    // delay - can show loader after X timeout
    if (loading && !canShowLoader) {
      if (timeoutInstance.current) {
        clearTimeout(timeoutInstance.current);
      }

      const timeout = setTimeout(() => {
        setCanShowLoader(true);
      }, delay ?? 0);
      setTimeoutInstance(timeout);
    }

    // minDuration - can hide loader after X timeout
    if (canShowLoader) {
      if (timeoutInstance.current) {
        clearTimeout(timeoutInstance.current);
      }

      const timeout = setTimeout(() => {
        setCanShowLoader(false);
      }, minDuration ?? 0);
      setTimeoutInstance(timeout);
    }
  }, [loading, canShowLoader, delay, setTimeoutInstance, minDuration]);

  useEffect(() => {
    if (loading && canShowLoader) {
      setShowLoader(true);
    }

    if (!loading && !canShowLoader) {
      setShowLoader(false);
    }
  }, [canShowLoader, loading]);

  if (showLoader) {
    return (
      <Button
        {...rest}
        sx={{
          ...(rest.sx ?? {}),
          "&:disabled": { opacity: "1 !important", color: "white !important" },
        }}
      >
        <ColoredBorderLoader />
        {loadingText && (
          <Box sx={{ position: "absolute", zIndex: 1 }}>{loadingText}</Box>
        )}
      </Button>
    );
  }

  if (loading) {
    return <Button {...rest} />;
  }

  return <Button {...rest}>{children}</Button>;
};
