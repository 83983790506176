import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import CopyToClipboard from "react-copy-to-clipboard";
import { PropsWithChildren, useState } from "react";
import { SxProps } from "@mui/material/styles";

interface ClipboardContentProps extends PropsWithChildren<any> {
  tooltipContext: string;
  clipboardValue: any;
  sx?: SxProps;
}

/**
 * The default way of displaying a value that can be copied to the clipboard.
 * @param tooltipContext Context to display in the tooltip. (e.g. "email address")
 * @param clipboardValue The value that should be copied to the clipboard.
 * @param children The content that needs to be visible to the user.
 * @param sx The sx prop to pass to the Box component.
 */
export const ClipboardContent = ({
  tooltipContext,
  clipboardValue,
  children,
  sx = {},
}: ClipboardContentProps) => {
  const [copied, setCopied] = useState(false);

  return (
    <Tooltip
      followCursor
      title={
        copied
          ? `Copied ${tooltipContext} to the clipboard!`
          : `Click to copy ${tooltipContext} to the clipboard`
      }
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: copied ? "grey.800" : "grey.50",
            "& .MuiTooltip-arrow": {
              color: copied ? "white" : "dark",
            },
            typography: { variant: "body2" },
          },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          "&:hover, input:hover": {
            fontWeight: "bold",
            cursor: "copy",
          },
          ...sx,
        }}
        onMouseLeave={() => setCopied(false)}
      >
        <CopyToClipboard text={clipboardValue} onCopy={() => setCopied(true)}>
          <Box sx={{ width: "100%" }}>{children}</Box>
        </CopyToClipboard>
      </Box>
    </Tooltip>
  );
};

export default ClipboardContent;
